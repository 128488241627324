import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import CouponCard from './CouponCard/card'; // Assuming CouponCard is in the same directory
import { useDispatch } from 'react-redux';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { NULLABLE_KEY, PATH_NAMES } from '@utils/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import CouponSkeleton from '@components/Skeleton/CouponSleleton';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { useLocation } from 'react-router-dom';
// import required modules
import { Navigation } from 'swiper';
import useCoupon from '@customhooks/useCoupon.js';
import { couponList, couponById } from '@reducers/couponReducer';

import './coupons.scss';

const breakpoints = {
  320: {
    slidesPerView: 1,
    slidesPerView: 'auto',
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  },
  768: {
    slidesPerView: 3,
    slidesPerView: 'auto',
  },
  1024: {
    slidesPerView: 4,
  },
  // Add more breakpoints as needed
};

const CouponList = (props) => {
  const [offersRef, isOfferView] = useInView({
    triggerOnce: true,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const location = useLocation();
  const pathLocation = location.pathname === PATH_NAMES.BOOKING ? true : false;
  const { fetchingCouponList, gettingCouponList } = useCoupon();
  const {
    data,
    applyAction,
    moreDetailAction,
    isSlider,
    fullView,
    id,
    cardCount,
    bankOffersOnly,
    offersOnly,
    allOffersList,
    title,
    titleText,
    listLength,
    propertyId,
  } = props;

  useEffect(() => {
    location?.pathname !== PATH_NAMES.BOOKING
      ? data == undefined && dispatch(couponList(id ? id : NULLABLE_KEY))
      : dispatch(couponById(id, propertyId));
  }, [id, propertyId, location.pathname]);

  const Offers =
    location?.pathname !== PATH_NAMES.BOOKING
      ? data !== undefined
        ? data
        : Array.isArray(gettingCouponList)
        ? gettingCouponList?.filter((item) => item?.Offer?.type_id !== 3)
        : []
      : gettingCouponList?.Coupons || [];

  const bankOffers =
    location?.pathname !== PATH_NAMES.BOOKING
      ? data !== undefined
        ? data
        : Array.isArray(gettingCouponList)
        ? gettingCouponList?.filter((item) => item?.Offer?.type_id === 3)
        : []
      : gettingCouponList?.BankCoupons || [];

  const allOffers = !allOffersList ? [bankOffers, Offers] : gettingCouponList;

  const mergedArray = Array.isArray(allOffers) ? [].concat(...allOffers) : [];

  const dataList = mergedArray;

  return allOffers?.length > 0 ? (
    <div ref={offersRef}>
      {isOfferView ? (
        <>
          {isSlider ? (
            fetchingCouponList ? (
              <Swiper
                slidesPerView={4}
                breakpoints={breakpoints}
                navigation={{
                  nextEl: '.coupon-img-next',
                  prevEl: '.coupon-img-prev',
                  clickable: true,
                }}
                modules={[Navigation]}
                spaceBetween={20}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                className='mySwiper EL-CouponSwiper'
              >
                <Grid container spacing={4}>
                  {dataList?.map((coupon) => (
                    <SwiperSlide key={coupon?.id}>
                      <Grid item xs={12} sm={12} md={12}>
                        <CouponCard
                          key={coupon?.id} // Make sure each card has a unique key
                          id={coupon?.id}
                          title={coupon?.name}
                          description={coupon?.description}
                          couponCode={coupon?.code}
                          couponIcon={
                            coupon?.media_icon_response?.formats === null
                              ? coupon?.media_icon_response?.url
                              : coupon?.media_icon_response?.formats?.thumbnail
                                  ?.url
                          }
                          tnc={coupon?.terms_and_conditions}
                          applyAction={applyAction ? applyAction : false}
                          fullView={fullView}
                          moreDetailAction={
                            moreDetailAction ? moreDetailAction : 'modal'
                          }
                          {...coupon}
                        />
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Grid>

                {allOffers?.length >= 4 && (
                  <>
                    {' '}
                    <div className='coupon-img-next'>
                      <ChevronRightIcon />
                    </div>
                    <div className='coupon-img-prev'>
                      <KeyboardArrowLeftIcon />
                    </div>
                  </>
                )}
              </Swiper>
            ) : (
              <CouponSkeleton
                listLength={listLength ? listLength : isMobile ? 1 : 4}
                type='grid'
              />
            )
          ) : fetchingCouponList ? (
            cardCount ? (
              dataList?.slice(0, cardCount).map((coupon) => (
                <CouponCard
                  key={coupon?.id} // Make sure each card has a unique key
                  id={coupon?.id}
                  title={coupon?.name}
                  description={coupon?.description}
                  couponCode={coupon?.code}
                  couponIcon={
                    coupon?.media_icon_response?.formats === null
                      ? coupon?.media_icon_response?.url
                      : coupon?.media_icon_response?.formats?.thumbnail?.url
                  }
                  tnc={coupon?.terms_and_conditions}
                  applyAction={applyAction ? applyAction : false}
                  fullView={fullView}
                  moreDetailAction={
                    moreDetailAction ? moreDetailAction : 'modal'
                  }
                  {...coupon}
                />
              ))
            ) : (
              dataList?.map((coupon) => (
                <CouponCard
                  key={coupon?.id} // Make sure each card has a unique key
                  id={coupon?.id}
                  title={coupon?.name}
                  description={coupon?.description}
                  couponCode={coupon?.code}
                  couponIcon={
                    coupon?.media_icon_response?.formats === null
                      ? coupon?.media_icon_response?.url
                      : coupon?.media_icon_response?.formats?.thumbnail?.url
                  }
                  tnc={coupon?.terms_and_conditions}
                  applyAction={
                    coupon?.Offer?.hollow_offer
                      ? applyAction
                        ? applyAction
                        : false
                      : coupon?.Offer?.type_id !== 3
                      ? pathLocation
                      : false
                  }
                  fullView={fullView}
                  moreDetailAction={
                    moreDetailAction ? moreDetailAction : 'modal'
                  }
                  {...coupon}
                />
              ))
            )
          ) : (
            <CouponSkeleton type='list' listLength={isMobile ? 1 : 2} />
          )}
        </>
      ) : (
        <CouponSkeleton listLength={4} />
      )}
    </div>
  ) : (
    <CouponSkeleton listLength={4} />
  );
};

export default CouponList;
