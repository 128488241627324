import { ElicashIcon } from '@assets'; // Ensure this path is correct
import Svg from '@components/Common/Svg'; // Ensure this path is correct
import useCoupon from '@customhooks/useCoupon';
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import {
  useElicashUpdate,
  applyElicashStatusUpdate,
} from '@reducers/globalReducers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function RedeemeElicash(props) {
  const { checkboxEnable, checkboxDisable, fetchingBookingDetailsRes } = props;
  const [checked, setChecked] = useState(true);
  const { loader } = useSelector((state) => state.glob);
  const { getWalletDetail } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const [useElicash, setUseElicash] = useState('');
  const [isCashbackAmount, setCashbackAmount] = useState(0);
  const { couponId, couponCode } = useCoupon();
  const { elicashCount, elicashApplied } = useSelector((state) => state?.glob);

  const performaInvoice =
    fetchingBookingDetailsRes?.ProformaInvoice?.Adjustments;

  const getPriceBySlug = (slug) => {
    const adjustment = performaInvoice?.find((adj) => adj.slug === slug);
    return adjustment ? adjustment.amount : null;
  };

  const cashbackAmount = getPriceBySlug('CASHBACK');
  const totalPayableAmount =
    getPriceBySlug('TOTAL_PAYABLE') !== null
      ? getPriceBySlug('TOTAL_PAYABLE')
      : getWalletDetail?.total_cashback;

  const payableNowAmount = getPriceBySlug('PAYABLE_NOW');

  useEffect(() => {
    const maxRedeemableCashback =
      fetchingBookingDetailsRes?.usable_cashback >
      getWalletDetail?.total_cashback
        ? getWalletDetail?.total_cashback
        : fetchingBookingDetailsRes?.usable_cashback;
    setCashbackAmount(maxRedeemableCashback);
  }, [fetchingBookingDetailsRes]);

  useEffect(() => {
    if (isCashbackAmount !== null && isCashbackAmount !== 0 && !checked) {
      setUseElicash(isCashbackAmount);
      dispatch(useElicashUpdate(isCashbackAmount));
      if (cashbackAmount !== isCashbackAmount) {
        dispatch(applyElicashStatusUpdate(!elicashApplied));
      }
    }
  }, [isCashbackAmount, dispatch]);

  const handleCheckboxChange = (event) => {
    const isChecked = !event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      dispatch(useElicashUpdate(0));
    } else {
      dispatch(useElicashUpdate(isCashbackAmount));
    }
    dispatch(applyElicashStatusUpdate(!elicashApplied));
  };

  // const handleBlur = (event) => {
  //   const value = Math.min(
  //     Number(event.target.value),
  //     getWalletDetail?.total_cashback
  //   );
  //   setUseElicash(value);
  //   dispatch(useElicashUpdate(value));
  // };

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     handleBlur(event);
  //   }
  // };

  // const handleInputChange = (event) => {
  //   let value = event.target.value?.replace(/^0+/, "");

  //   const upperLimit = Math.min(
  //     getWalletDetail?.total_cashback,
  //     fetchingBookingDetailsRes?.usable_cashback
  //   );

  //   value = Math.min(Number(value), upperLimit);

  //   setUseElicash(value);
  // };

  useEffect(() => {
    if (elicashCount == 0 && !checked) {
      setChecked(true);
    }
  }, [elicashCount]);

  useEffect(() => {
    if (cashbackAmount != 0 && cashbackAmount != null) {
      setChecked(false);
    }
  }, [cashbackAmount]);
  return (
    <>
      {checkboxEnable && !checkboxDisable && (
        <>
          <Grid container spacing={1} width={'100%'}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormGroup className='EL-RedeemCheckbox'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!checked}
                      disabled={loader || getWalletDetail?.total_cashback === 0}
                      onChange={handleCheckboxChange}
                    />
                  }
                />
              </FormGroup>
              <Grid container className='EL-Redeem EL-WithoutField'>
                <Typography variant='h6' gutterBottom>
                  Pay using Elicash{' '}
                </Typography>
                <Typography
                  className='EL-RemainingBal'
                  variant='body2'
                  gutterBottom
                >
                  Balance: <Svg src={ElicashIcon} />{' '}
                  <span>{getWalletDetail?.total_cashback}</span>
                </Typography>
                <Chip
                  label={
                    <div>
                      <Typography fontWeight={700} variant='h1'>
                        Up to{' '}
                        {fetchingBookingDetailsRes?.cashback_cap_percentage
                          ? fetchingBookingDetailsRes?.cashback_cap_percentage
                          : 0}
                        %
                      </Typography>
                      <Typography fontWeight={400} variant='body2'>
                        &nbsp;on Total Payable amount
                      </Typography>
                    </div>
                  }
                />
              </Grid>

              <Grid container className='EL-RedeemRight'>
                <FormControl variant='standard'>
                  <Input
                    disableUnderline
                    disabled={loader || checked}
                    type='number'
                    value={isCashbackAmount}
                    placeholder={useElicash == 0 ? 0 : ''}
                    // onBlur={handleBlur}
                    // onChange={handleInputChange}
                    // onKeyDown={handleKeyDown}
                    endAdornment={
                      <InputAdornment position='end'>
                        {loader ? (
                          <CircularProgress size={18} color='inherit' />
                        ) : (
                          <Svg src={ElicashIcon} />
                        )}
                      </InputAdornment>
                    }
                    aria-describedby='standard-weight-helper-text'
                    inputProps={{
                      'aria-label': 'weight',
                      min: 0,
                      max: getWalletDetail?.total_cashback,
                      readOnly: true,
                      style: { fontSize: '14px', textAlign: 'center' },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default RedeemeElicash;
