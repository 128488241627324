import { LogoBlack, Logowhite } from '@assets';
import { Link, Typography } from '@mui/material';
import Svg from '@components/Common/Svg';
import * as React from 'react';
import './header.scss';

export const Logo = (props) => {
  const { logoColor, height, width } = props;

  return (
    <>
      <Typography
        className='logo'
        variant='h6'
        component='div'
        sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
      >
        <Link href='/'>
          <Svg
            src={logoColor === 'black' ? LogoBlack : Logowhite}
            alt='Elivaas logo'
          />
        </Link>
      </Typography>
    </>
  );
};

export default Logo;
