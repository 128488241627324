import React from 'react';

// material
import {
  Button,
  DialogActions,
  DialogContent,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddGuestList from './AddGuest';

// scss import
import './guest.scss';

// component start
const GuestOpen = ({
  isOpen,
  handleGuestClose,
  numberOfChildren,
  numberOfGuests,
  setNumberOfGuests,
  setNumberOfChildren,
  handleUpdatingPricing,
  setBasicDetails,
  basicDetails,
  fetchingUpdatingPricing,
  max_occupancy,
  standard_guests,
  max_children,
  max_adults,
  staahMapping,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isCallbackAnchor = document.getElementById(
    'number-of-guests-button-callback'
  );
  return !isMobile ? (
    <div
      className={`${
        !isOpen ? 'El-guestModal' : 'El-guestModal EL-guestModalOpen'
      }`}
    >
      <AddGuestList
        numberOfChildren={numberOfChildren}
        numberOfGuests={numberOfGuests}
        setNumberOfGuests={setNumberOfGuests}
        setNumberOfChildren={setNumberOfChildren}
        handleUpdatingPricing={handleUpdatingPricing}
        setBasicDetails={setBasicDetails}
        basicDetails={basicDetails}
        fetchingUpdatingPricing={fetchingUpdatingPricing}
        max_occupancy={max_occupancy}
        standard_guests={standard_guests}
        max_children={max_children}
        max_adults={max_adults}
        staahMapping={staahMapping}
      />
    </div>
  ) : (
    <Drawer
      anchor={'bottom'}
      open={isOpen}
      onClose={handleGuestClose}
      className='mobileDatePickerModal'
    >
      <DialogContent>
        <AddGuestList
          isOpen={isOpen}
          handleGuestClose={handleGuestClose}
          isDrawer={false}
          handleUpdatingPricing={handleUpdatingPricing}
          numberOfGuests={numberOfGuests}
          setNumberOfGuests={setNumberOfGuests}
          numberOfChildren={numberOfChildren}
          setNumberOfChildren={setNumberOfChildren}
          fetchingUpdatingPricing={fetchingUpdatingPricing}
          max_occupancy={max_occupancy}
          standard_guests={standard_guests}
          max_children={max_children}
          max_adults={max_adults}
          staahMapping={staahMapping}
        />
      </DialogContent>
      <DialogActions>
        <div className='el-nights'>
          <Typography variant='button' display='block' gutterBottom>
            {`${numberOfGuests + numberOfChildren} Guest(s)`}
          </Typography>
          <Typography variant='caption' display='block' gutterBottom>
            Selected
          </Typography>
        </div>
        <Button
          size='large'
          variant='contained'
          disableElevation
          onClick={handleUpdatingPricing}
          className='continue-button'
        >
          Continue
        </Button>
      </DialogActions>
    </Drawer>
  );
};

export default GuestOpen;
