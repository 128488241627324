import React, { useState } from 'react';
import {
  Drawer,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CouponCard from './card'; // Assuming CouponCard is in the same directory

const CustomDialogTitle = (onClose) => {
  return (
    <DialogTitle className='EL-DilogueTitle' id='customized-dialog-title'>
      <span>Offer Details</span>
      <IconButton aria-label='close' onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default function CouponView(props) {
  const { openCoupon, setOpenCoupon, coupon } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerClose = () => {
    setOpenCoupon(false);
  };

  return (
    <>
      {isMobile ? (
        // Render drawer for mobile
        <Drawer
          anchor='bottom'
          size='lg'
          open={openCoupon}
          onClose={handleDrawerClose}
          className='EL-CouponDrawer'
        >
          {CustomDialogTitle(handleDrawerClose)}

          <CouponCard
            id={coupon?.id}
            title={coupon?.title}
            description={coupon?.description}
            couponCode={coupon?.couponCode}
            couponIcon={coupon?.couponIcon}
            tnc={coupon?.tnc}
            applyAction={false}
            moreDetailAction={'expand'}
            isExpand={true}
            fullView={true}
          />
        </Drawer>
      ) : (
        // Render modal for web
        <Dialog
          className='EL-CouponDialog'
          open={openCoupon}
          onClose={handleDrawerClose}
        >
          {CustomDialogTitle(handleDrawerClose)}
          <CouponCard
            id={coupon?.id}
            title={coupon?.title}
            description={coupon?.description}
            couponCode={coupon?.couponCode}
            couponIcon={coupon?.couponIcon}
            tnc={coupon?.tnc}
            applyAction={false}
            moreDetailAction={'expand'}
            fullView={true}
          />
        </Dialog>
      )}
    </>
  );
}
