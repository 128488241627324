import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Box,
  Drawer,
  Typography,
} from '@mui/material';

import { useLocation } from 'react-router-dom';
import useCoupon from '@customhooks/useCoupon.js';
import CouponList from '@components/Coupons';
import CouponField from '../CouponField';
import CloseIcon from '@mui/icons-material/Close';
import { PATH_NAMES } from '@utils/constants';

function CustomTabPanel(props) {
  const {
    children,
    value,
    index,

    ...other
  } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='EL-CouponTabPanal'>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const CouponListModal = (props) => {
  const location = useLocation();
  const { fetchingCouponList, gettingCouponList } = useCoupon();
  const [couponTypeValue, setCouponTypeValue] = useState(1);
  const {
    applyAction,
    fullView,
    moreDetailAction,
    setOpenCouponsList,
    openCouponsList,
    id,
    propertyId,
    isCouponApplied,
    applyButtonClicked,
    setApplyButtonClicked,
    isDoubleDip,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleModalClose = () => {
    setOpenCouponsList(false);
  };

  const handleModalOpen = () => {
    setOpenCouponsList(true);
  };

  const handleTabChange = (event, newValue) => {
    setCouponTypeValue(newValue);
  };

  const renderCouponList = (
    applyAction,
    moreDetailAction,
    id,
    offersOnly,
    bankOffersOnly,
    skeletonLength,
    data
  ) => (
    <CouponList
      fullView={fullView ? fullView : false}
      applyAction={applyAction}
      moreDetailAction={moreDetailAction || 'expand'}
      isSlider={false}
      id={id}
      // offersOnly={offersOnly}
      // bankOffersOnly={bankOffersOnly}
      allOffersList={true}
      skeletonLength={skeletonLength}
      propertyId={propertyId}
      isDoubleDip={isDoubleDip}
      data={data}
    />
  );

  const pathLocation = location.pathname === PATH_NAMES.BOOKING ? true : false;
  return (
    <>
      {isMobile ? (
        <Drawer
          anchor='bottom'
          size='lg'
          open={openCouponsList}
          onClose={handleModalClose}
          className='EL-CouponListDrawer'
        >
          <DialogTitle className='EL-DilogueTitle'>
            <span>Apply Coupon</span>
            <IconButton aria-label='close' onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {!isDoubleDip && applyAction && (
            <div className='EL-couponField'>
              <CouponField
                applyButtonClicked={applyButtonClicked}
                setApplyButtonClicked={setApplyButtonClicked}
                setOpenCouponsList={setOpenCouponsList}
              />
            </div>
          )}
          <div className='EL-CouponListDrawerInner'>
            {/* <Typography variant='h5' gutterBottom>
              Best Coupon{' '}
            </Typography> */}

            {renderCouponList(
              pathLocation,
              moreDetailAction,
              id,
              true,
              false,
              1,
              gettingCouponList?.Offers
            )}
            {/* <Typography variant='h5' gutterBottom>
              Payment Offers<span>No coupon code required</span>
            </Typography> */}
            {/* {renderCouponList(
              pathLocation,
              moreDetailAction,
              id,
              false,
              true,
              1,
              gettingCouponList?.BankOffers
            )} */}
          </div>
        </Drawer>
      ) : (
        <Dialog
          className='EL-CouponListDialog'
          open={openCouponsList}
          onClose={handleModalOpen}
        >
          <DialogTitle className='EL-DilogueTitle'>
            <span>Apply Coupon</span>
            <IconButton aria-label='close' onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Box className='El-offerTebMain'>
            {!isDoubleDip && applyAction && (
              <CouponField
                applyButtonClicked={applyButtonClicked}
                setApplyButtonClicked={setApplyButtonClicked}
                setOpenCouponsList={setOpenCouponsList}
              />
            )}
            <Box>
              {renderCouponList(
                pathLocation,
                moreDetailAction,
                id,
                false,
                true,
                1,
                gettingCouponList?.BankOffers
              )}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default CouponListModal;
