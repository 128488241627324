import axios from 'axios';

export const submitHubspotForm = async (portalId, formGuid, fields) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields,
      },
      config
    );
    return console.log('HubSpot form: Response', response.data); // Return only the response data
  } catch (error) {
    console.log('Error submitting HubSpot form:', error);
    console.error('Error submitting HubSpot form:', error);
    throw new Error('Failed to submit HubSpot form'); // Throw an error if submission fails
  }
};
