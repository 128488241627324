import { WalletIcon, ElicashIcon } from '@assets';
import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Tooltip,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Svg from '@components/Common/Svg';
import HelpIcon from '@mui/icons-material/Help';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import './wallet.scss';

export default function Elivashcard(props) {
  const theme = useTheme();
  const {
    elicash,
    accordionView,
    giftImage,
    defaultView,
    withoutTooltip,
    accordionViewWithoutText,
    isCompletePayment,
    loader,
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const RepeatedText = () => {
    const content =
      'Elicash will be credited to your wallet post your stay completion';
    return content;
  };

  return (
    <>
      {accordionView && (
        <Accordion className='EL-wallet_accordion'>
          <AccordionSummary
            expandIcon={
              isMobile ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )
            }
            aria-controls='panel1-content'
            id='panel1-header'
            className='EL-AccordionSummary'
          >
            <div className='EL-walletInner'>
              <Svg src={WalletIcon} />
              <div className='EL-walletInner-2S'>
                <span>You will earn</span>
                <Svg src={ElicashIcon} />
                <span>{elicash ? elicash : '***'}</span> <span>Elicash</span>{' '}
                <br className='EL-walletBrTagOpp' />{' '}
                <span className='EL-walletOnthisbooking'>on this booking</span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ fontWeight: 'light', fontSize: '12px', color: '#0000008A' }}
            >
              {/* <hr className='EL-Line' /> */}
              <Divider className='EL-Line' />
              {RepeatedText()}
            </Typography>
            {isCompletePayment && (
              <Typography
                variant='body2'
                sx={{ fontWeight: '400' }}
                className='highlightMessage'
              >
                Complete your payment to earn Elicash
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {accordionViewWithoutText && (
        <Accordion className='EL-wallet_accordion'>
          <AccordionSummary
            expandIcon={
              isMobile ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )
            }
            aria-controls='panel1-content'
            id='panel1-header'
            className='EL-AccordionSummary'
          >
            <div className='EL-walletInner'>
              <Svg src={WalletIcon} />
              <div className='EL-walletInner-2S'>
                <span>You will earn</span>
                <Svg src={ElicashIcon} />
                <span>{elicash ? elicash : '***'}</span> <span>Elicash</span>{' '}
                <br className='EL-walletBrTagOpp' />{' '}
                <span className='EL-walletOnthisbooking'>on this booking</span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ fontWeight: 'light', fontSize: '12px', color: '#0000008A' }}
            >
              {/* <hr className='EL-Line' /> */}
              <Divider className='EL-Line' />
              {RepeatedText()}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      {giftImage && (
        <Grid className='EL-walletBox'>
          <div className='EL-connfirmInfoField'>
            <Paper variant='elevation' square>
              <div className='EL-walletInner'>
                <Svg src={WalletIcon} />
                <div className='EL-walletInner-2'>
                  <span>You will earn</span>
                  <Svg src={ElicashIcon} />
                  <span>{elicash ? elicash : '***'}</span> <span>Elicash</span>{' '}
                  <br className='EL-walletBrTag' />{' '}
                  <span className='EL-walletOnthisbooking'>
                    on this booking
                  </span>
                </div>
              </div>
              <Tooltip
                sx={{ padding: '16px 20px !important' }}
                title={
                  <div className='EL-tooltipContent'>{RepeatedText()}</div>
                }
                open={showTooltip}
                placement='top-end'
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                arrow
                classes={{
                  tooltip: 'giftImage-custom-tooltip',
                  arrow: 'giftImage-custom-tooltip-arrow',
                }}
              >
                <HelpIcon onClick={() => setShowTooltip(!showTooltip)} />
              </Tooltip>
            </Paper>
          </div>
        </Grid>
      )}

      {defaultView && (
        <Grid className='EL-walletBoxDef'>
          <Paper variant='elevation' square>
            <div className='EL-walletInnerDef'>
              <span>You will earn</span>
              <Svg src={ElicashIcon} />
              <span>{elicash ? elicash : '***'}</span> <span>Elicash</span>
            </div>
            <Tooltip
              className='EL-BookingPageTooltip'
              title={<div className='EL-tooltipContent'>{RepeatedText()}</div>}
              open={showTooltip}
              placement='bottom'
              onOpen={() => setShowTooltip(true)}
              onClose={() => setShowTooltip(false)}
              arrow
              classes={{
                tooltip: 'defaultView-custom-tooltip',
                arrow: 'defaultView-custom-tooltip-arrow',
              }}
            >
              <HelpIcon onClick={() => setShowTooltip(!showTooltip)} />
            </Tooltip>
          </Paper>
        </Grid>
      )}

      {withoutTooltip && (
        <Grid className='EL-walletBoxWithoutTooltip'>
          <Svg src={WalletIcon} />
          <Paper variant='elevation' square>
            <div className='EL-walletInnerDefwithout'>
              <span>You will earn</span>
              <Svg src={ElicashIcon} />
              <span>{elicash ? elicash : 2000}</span> <span>Elicash</span>
              <span className='EL-walletOnthisbooking'>on this booking</span>
              <div className='EL-tooltipContentWithout'>{RepeatedText()}</div>
            </div>
          </Paper>
        </Grid>
      )}
    </>
  );
}
