import {
  About,
  Booking,
  Call,
  Logout,
  Partner,
  Profile,
  Team,
  Elicash,
  Wallet,
} from '@assets';
import Svg from '@components/Common/Svg';
import ElicashChip from '@components/Wallet/ElicashChip';
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setElicashOpen } from '@reducers/globalReducers';
import { contact } from '@utils/customText';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const commonMenuItems = [
  { id: 4, name: 'Partner with us', link: 'explore/partner', img: Partner },
  { id: 5, name: 'Our Team', link: 'explore/team', img: Team },
  { id: 6, name: 'About Us', link: 'explore/about', img: About },
  {
    id: 7,
    name: `${contact.number}`,
    link: `tel:${contact.number}`,
    img: Call,
  }, // Updated link

  // { id: 8, name: '+91-7827449532', link: '7827449532', img: Call },
];

const conditionalMenuItemsMobile = [
  { id: 1, name: 'My Profile', link: 'myAccount/profile', img: Profile },
  { id: 2, name: 'Manage Bookings', link: 'myAccount/booking', img: Booking },
  { id: 3, name: 'My Wallet', link: 'myAccount/my-wallet', img: Wallet },
  ...commonMenuItems,
];

const conditionalMenuItemsWeb = [
  { id: 1, name: 'My Profile', link: 'myAccount/profile', img: Profile },
  { id: 2, name: 'Manage Bookings', link: 'myAccount/booking', img: Booking },
  { id: 3, name: 'My Wallet', link: 'myAccount/my-wallet', img: Wallet },
  { id: 3, name: 'Logout', link: '', img: Logout },
];

const MenuLinks = (props) => {
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState('aaa');
  const cookies = new Cookies();
  const { handleDrawerToggle, handleClose } = props;
  const { getWalletDetail, gettingWalletListLoading } = useSelector(
    (state) => state.wallet
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    setActiveLink(location.pathname === '/' ? 'logout' : location.pathname);
  }, [location.pathname]);

  const menuItems = isMobile
    ? localStorage.getItem('refreshToken')
      ? conditionalMenuItemsMobile
      : commonMenuItems
    : conditionalMenuItemsWeb;

  const handleElicashModal = () => {
    dispatch(setElicashOpen(true));
  };

  const handleLinkOpen = (name, link) => {
    if (name === 'Logout') {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('firstname');
      localStorage.removeItem('lastname');
      localStorage.removeItem('mobile');
      window.location.href = '/';
      handleDrawerToggle();
    } else {
      setActiveLink(link);
      if (isMobile) {
        handleDrawerToggle();
      } else if (handleClose) {
        handleClose();
      }

      if (
        link === 'explore/about' ||
        link === 'explore/partner' ||
        link === 'explore/team'
      ) {
        window.location.href = `https://www.elivaas.com/${link}`;
      } else {
        if (link.startsWith('tel:')) {
          window.open(link, '_blank');
        } else {
          window.location.href = `/${link}`;
        }
      }
    }
  };

  return (
    <>
      <Divider />
      {menuItems?.map((item, index) => (
        <div key={`links${index}`}>
          <ListItem
            className={activeLink === `${item.link}` ? 'menuActive' : ''}
            disablePadding
          >
            <ListItemButton
              selected={activeLink === `/${item.link}`}
              onClick={() => handleLinkOpen(item.name, item.link)}
            >
              {item?.img && (
                <ListItemIcon>
                  <Svg src={item?.img} alt={item?.name} />
                </ListItemIcon>
              )}
              <ListItemText primary={item?.name} />{' '}
              {item?.name == 'My Wallet' && (
                <ElicashChip
                  label={getWalletDetail?.total_cashback}
                  variant='filled'
                />
              )}
            </ListItemButton>
          </ListItem>

          <Divider />
        </div>
      ))}
    </>
  );
};

export default MenuLinks;
