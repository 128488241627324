import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Button, OutlinedInput } from '@mui/material';
import CouponStatusDialogue from './CouponStatusDialogue';

import useCoupon from '@customhooks/useCoupon.js';
import { setOpen } from '@reducers/globalReducers';
import Login from '@components/Login/LoginModal';
import { userDetails } from '@utils/common';
import { useElicashUpdate } from '@reducers/globalReducers';
import { appliedCustomCouponCode } from '@reducers/couponReducer';

const CouponField = (props) => {
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState(''); // Add state for coupon code
  const [statusDialiuge, setStatusDialiuge] = useState(false); // Add state for coupon code
  const [isCouponStatus, setCouponStatus] = useState(false); // Add state for coupon code
  const [isCouponErronMassage, setCouponErrorMassage] = useState(false); // Add state for coupon error massage
  const { isCouponApplied, gettingCouponResponse } = useCoupon();
  const { setOpenCouponsList, applyButtonClicked, setApplyButtonClicked } =
    props;
  const { loader, open } = useSelector((state) => state.glob);
  const { gettingProfileDetails } = useSelector((state) => state.profile);

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value); // Update coupon code state
  };

  const handleCustomCouponApply = () => {
    if (!userDetails(gettingProfileDetails)?.guest_id) {
      dispatch(setOpen(true));
      return;
    }
    if (couponCode.trim() !== '') {
      dispatch(useElicashUpdate(0));
      dispatch(appliedCustomCouponCode(couponCode));
      setApplyButtonClicked(!applyButtonClicked);
    }
  };

  // Open Dialiuge, setting error code in case of not applicable and invalid and massage//
  useEffect(() => {
    if (gettingCouponResponse?.status_code == 400 && couponCode.trim() !== '') {
      setCouponStatus(gettingCouponResponse?.error_code);
      setCouponErrorMassage(gettingCouponResponse?.error_message);
      setStatusDialiuge(true);
    }
  }, [gettingCouponResponse]);

  return (
    <>
      <FormControl
        className='EL-applyCouponField'
        fullWidth
        sx={{ mt: 2 }}
        variant='outlined'
      >
        <OutlinedInput
          id='outlined-adornment-weight'
          placeholder='Enter coupon code'
          value={couponCode} // Bind input value to coupon code state
          onChange={handleCouponCodeChange} // Handle input change
          endAdornment={
            <Button
              onClick={() => {
                handleCustomCouponApply(couponCode);
              }} // Pass coupon code to apply method
              size='large'
              variant='contained'
              disableElevation
              // disableElevation={loader}
              disabled={
                (loader && couponCode.trim() !== '') || couponCode.trim() == ''
              }
            >
              {loader && couponCode.trim() !== '' ? 'Applying...' : 'Apply'}
            </Button>
          }
        />
      </FormControl>

      {/* apply coupon status Dialogue*/}
      <CouponStatusDialogue
        status={isCouponStatus}
        setOpenStatusModal={setStatusDialiuge}
        openStatusModal={statusDialiuge}
        setOpenCouponsList={setOpenCouponsList}
        setCouponStatus={setCouponStatus}
        isCouponDiscount={isCouponErronMassage}
      />

      <Login setOpen={setOpen} open={open} />
    </>
  );
};

export default CouponField;
