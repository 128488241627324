import Skeleton from '@components/Common/Skeleton';
import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useInView } from 'react-intersection-observer';
import initializeAOS from '@utils/aos';
import { progress } from '@utils/customText';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import useBookingDetails from '@customhooks/useBookingDetails';
import useCoupon from '@customhooks/useCoupon.js';
import { detailContent } from '@utils/customText';
import { mergedDataAndReturnLengthOrArray } from '@utils/common';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CheckinCheckoutDetail from './CheckinCheckoutField';
import CouponSkeleton from '@components/Skeleton/CouponSleleton';
import useAnalytics from '@components/Common/Analytics';
import './detail.scss';

// import components
import CouponListModal from '@components/Coupons/CouponListModal';
const ImagesList = lazy(() => import('./Images'));
const FaqsList = lazy(() => import('./FAQs'));
const About = lazy(() => import('./About'));
const SpacesList = lazy(() => import('./Spaces'));
const Cta = lazy(() => import('./Cta'));
const Amenities = lazy(() => import('./Amenities'));
const Rating = lazy(() => import('./Rating'));
const Location = lazy(() => import('./Location'));
const Meal = lazy(() => import('./Meal'));
const Insides = lazy(() => import('./Insides'));
const Demand = lazy(() => import('./Demand'));
const Reviews = lazy(() => import('./Reviews'));
const PropertyName = lazy(() => import('@components/Property/Name'));
const PropertyPrice = lazy(() => import('@components/Property/Price'));
const BottomNavigationCAT = lazy(() => import('./BottomNavigation'));
const Matterport = lazy(() => import('./Matterport'));
const CouponList = lazy(() => import('@components/Coupons'));

export const Detail = (props) => {
  const { trackEvent } = useAnalytics();
  const [imagesRef, isImagesView] = useInView({
    triggerOnce: true,
  });

  const [offersRef, isOfferView] = useInView({
    triggerOnce: true,
  });
  const [amenitiesRef, isAmenitiesView] = useInView({
    triggerOnce: true,
  });
  const [mealRef, isMealView] = useInView({
    triggerOnce: true,
  });

  const [spaceRef, isSpaceView] = useInView({
    triggerOnce: true,
  });
  const [addressRef, isAddressView] = useInView({
    triggerOnce: true,
  });
  const [matterportRef, isMatterportView] = useInView({
    triggerOnce: true,
  });
  const [spanshotRef, isSpanshotView] = useInView({
    triggerOnce: true,
  });
  const [houseRuleRef, isHouseRuleView] = useInView({
    triggerOnce: true,
  });
  const [reviewsRef, isReviewsView] = useInView({
    triggerOnce: true,
  });

  const [faqRef, isFaqView] = useInView({
    triggerOnce: true,
  });
  const [ratingRef, isRatingView] = useInView({
    triggerOnce: true,
  });
  const [bottomNavigationRef, isBottomNavigationView] = useInView({
    triggerOnce: true,
  });
  const [propertyNameRef, isPropertyNameView] = useInView({
    triggerOnce: true,
  });

  const { dataJson, bookingId } = props;
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [openCouponsList, setOpenCouponsList] = useState(false);
  const { gettingCouponList } = useCoupon();
  const {
    id,
    Address,
    Images,
    Page,
    PropertyChannels,
    virtual_tour_url,
    Spaces,
    average_rating,
    booking_count,
    ChannelReviews,
    Metrics,
    name,
    TopAmenities,
    AmenitiesGroups,
    Offers,
    phone_number,
    price_amount,
    property_id,
    trip_advisor_location_id,
    TripAdvisorReviews,
    whatsapp_number,
    max_occupancy,
    standard_guests,
    max_children,
    max_adults,
    staahMapping,
  } = dataJson;

  const { fetchingBookingDetailsRes, setIsModifyClicked } = useBookingDetails();
  const { utmCampaign, utmSource } = useSelector((state) => state?.glob);
  const { fetchingUpdatingPricing, gettingUpdatedPricing } = useSelector(
    (state) => state.property
  );

  const { loader } = useSelector((state) => state.glob);

  const getPriceBySlug = (slug) => {
    const adjustment =
      fetchingBookingDetailsRes?.ProformaInvoice?.Adjustments?.find(
        (adj) => adj.slug === slug
      );
    return adjustment ? adjustment.amount : null;
  };

  const payableAmount =
    getPriceBySlug('PER_NIGHT_PAYABLE_AMOUNT') !== null
      ? getPriceBySlug('PER_NIGHT_PAYABLE_AMOUNT')
      : progress.priceLoading;
  const updatedAmount = bookingId
    ? payableAmount
    : fetchingUpdatingPricing && Object.keys(gettingUpdatedPricing).length > 0
    ? gettingUpdatedPricing?.Inventory?.per_night_payable_amount
    : price_amount;

  const totalPayableAmount = bookingId
    ? payableAmount
    : fetchingUpdatingPricing && Object.keys(gettingUpdatedPricing).length > 0
    ? gettingUpdatedPricing?.Inventory?.payable_amount
    : price_amount;

  useEffect(() => {
    initializeAOS();
  }, []);
  const isMobile = useMediaQuery('(max-width: 767px)');
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsSticky(scrollTop >= 620);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const filteredPageData = (data, slug) => {
    return data?.filter((section) => section.slug === slug)?.[0];
  };

  const propertyChannelLink = (data, channel) => {
    return data?.filter((channels) => channels.name === channel)?.[0];
  };

  const handleModalOpen = () => {
    setOpenCouponsList(true);
  };

  const pathName = location.pathname;

  // Property Details Page Viewed event trigger
  useEffect(() => {
    let timeoutId;
    const parsedFilterValues = JSON.parse(localStorage.getItem('filterValues'));
    if (property_id || bookingId) {
      timeoutId = setTimeout(() => {
        trackEvent('Property Details Page Viewed', {
          property_name: name,
          checkInDate: parsedFilterValues?.checkInDate,
          checkOutDate: parsedFilterValues?.checkOutDate,
          numberOfGuests: parsedFilterValues?.numberOfGuests,
          numberOfChildren: parsedFilterValues?.numberOfChildren,
          property_id: property_id,
          booking_id: bookingId,
          utmCampaign: utmCampaign,
          utmSource: utmSource,
        });
      }, 3000);
    }

    // Clean up the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [property_id, bookingId]); // Run the effect only when the title prop changes

  return (
    <Container
      maxWidth='lg'
      id='propertyDetailContainer'
      className='el-section propertyDetailContainer'
    >
      <div className='el-detailPageContainer'>
        <Grid ref={imagesRef} container spacing={0}>
          {/*===== IMAGE SECTION =====*/}
          <Grid item xs={12} sm={12} className='topMediaSection'>
            {isImagesView && Images && (
              <Paper elevation={0}>
                <Suspense
                  fallback={
                    <div className='slekton-flex'>
                      <Skeleton
                        variant='rounded'
                        width={isMobile ? '78%' : '93%'}
                        height={isMobile ? '30vh' : '54vh'}
                      />
                      {isMobile ? (
                        <div>
                          <Skeleton
                            variant='rounded'
                            width={'100%'}
                            height={'14.5vh'}
                          />
                          <Skeleton
                            variant='rounded'
                            width={'100%'}
                            height={'14.5vh'}
                          />
                        </div>
                      ) : (
                        <>
                          <div>
                            <Skeleton
                              variant='rounded'
                              width={'100%'}
                              height={'26vh'}
                            />
                            <Skeleton
                              variant='rounded'
                              width={'100%'}
                              height={'26vh'}
                            />
                          </div>
                          <div>
                            <Skeleton
                              variant='rounded'
                              width={'100%'}
                              height={'26vh'}
                            />
                            <Skeleton
                              variant='rounded'
                              width={'100%'}
                              height={'26vh'}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  }
                  mode='concurrent'
                >
                  <ImagesList data={Images} />
                </Suspense>
              </Paper>
            )}
          </Grid>
        </Grid>
        {/*===== IMAGE SECTION END =====*/}
        <Grid container spacing={2}>
          {/*===== LEFT SECTION =====*/}

          <Grid item xs={12} sm={8} className='leftSection'>
            {virtual_tour_url !== null && (
              <Paper
                ref={matterportRef}
                className=' EL-metaportDetail'
                elevation={0}
              >
                <Suspense fallback={'Loading...'} mode='concurrent'>
                  {isMatterportView && (
                    <Matterport matterportKey={virtual_tour_url} />
                  )}
                </Suspense>
              </Paper>
            )}
            {/* name and location */}
            {Address && (
              <Paper
                ref={propertyNameRef}
                className='paperDetail'
                elevation={0}
              >
                {isPropertyNameView && (
                  <>
                    <Suspense
                      fallback={
                        <>
                          <Skeleton component='h1' variant='text' height={45} />
                          <Skeleton component='h3' variant='text' height={20} />
                        </>
                      }
                      mode='concurrent'
                    >
                      <PropertyName
                        className={'detailName'}
                        name={name}
                        address={Address}
                        rating={average_rating}
                        page='detail'
                      />
                    </Suspense>

                    {Metrics && (
                      <Suspense
                        fallback={
                          <>
                            <Skeleton
                              component='h3'
                              variant='text'
                              height={20}
                            />
                          </>
                        }
                        mode='concurrent'
                      >
                        <Insides
                          className={'el-detailPopular'}
                          data={Metrics}
                        />
                      </Suspense>
                    )}
                  </>
                )}
              </Paper>
            )}

            {/* about villa */}
            {Page?.Sections && (
              <Paper ref={spanshotRef} className='paperDetail' elevation={0}>
                <Typography variant='h4' gutterBottom>
                  {detailContent.villaSnap}
                </Typography>
                {isSpanshotView && (
                  <Suspense
                    fallback={
                      <>
                        {Array.from({ length: 4 }, (_, index) => (
                          <Skeleton key={index} component='p' variant='text' />
                        ))}
                      </>
                    }
                    mode='concurrent'
                  >
                    <About
                      className='el-aboutDetail'
                      data={filteredPageData(Page?.Sections, 'VILLA_SNAPSHOT')}
                    />
                  </Suspense>
                )}
              </Paper>
            )}

            {/* villa amenities */}

            {TopAmenities && (
              <Paper ref={amenitiesRef} className='paperDetail' elevation={0}>
                <Typography variant='h4' gutterBottom>
                  {detailContent.amenities}
                </Typography>
                <Suspense
                  fallback={
                    <>
                      <Skeleton component='div' variant='text' height={96} />{' '}
                      <Skeleton
                        component='div'
                        variant='text'
                        height={40}
                        width={150}
                      />
                    </>
                  }
                  mode='concurrent'
                >
                  {isAmenitiesView && (
                    <Amenities
                      className='el-amenitiesDetails'
                      data={TopAmenities}
                      dataGroup={AmenitiesGroups}
                    />
                  )}
                </Suspense>
              </Paper>
            )}

            {/* mobile offers */}

            {isMobile && (
              <Paper
                ref={offersRef}
                className='paperDetail EL-detailOfferMobile'
                elevation={0}
              >
                {isOfferView && (
                  <Suspense fallback={<CouponSkeleton listLength={1} />}>
                    {/* <OffersList className='el-offerDetail' data={Offers} /> */}
                    <Typography variant='h4' gutterBottom>
                      {`Offers (${gettingCouponList.length})`}
                    </Typography>

                    <CouponList
                      applyAction={false}
                      moreDetailAction='modal'
                      isSlider={true}
                      propertyId={property_id}
                      allOffersList={true}
                      skeletonLength={1}
                      id={id}
                    />
                  </Suspense>
                )}
              </Paper>
            )}

            {/* villa meal section */}
            {Page?.Sections && (
              <Paper ref={mealRef} className='paperDetail' elevation={0}>
                <Typography variant='h4' gutterBottom>
                  {detailContent.meals}
                </Typography>
                {isMealView && (
                  <Suspense
                    fallback={Array.from({ length: 4 }, (_, index) => (
                      <Skeleton key={index} component='p' variant='text' />
                    ))}
                    mode='concurrent'
                  >
                    <Meal
                      className='el-mealDetail'
                      data={filteredPageData(Page?.Sections, 'MEALS')}
                    />
                  </Suspense>
                )}
              </Paper>
            )}

            {/* villa houseRules section */}
            {Page?.Sections && (
              <Paper ref={houseRuleRef} className='paperDetail' elevation={0}>
                <Typography variant='h4' gutterBottom>
                  {detailContent.houseRules}
                </Typography>
                {isHouseRuleView && (
                  <Suspense
                    fallback={Array.from({ length: 4 }, (_, index) => (
                      <Skeleton key={index} component='p' variant='text' />
                    ))}
                    mode='concurrent'
                  >
                    <About
                      className='el-aboutDetail'
                      data={filteredPageData(Page?.Sections, 'HOUSE_RULES')}
                    />
                  </Suspense>
                )}
              </Paper>
            )}

            {/* villa space section */}
            {Spaces && (
              <Paper ref={spaceRef} className='paperDetail' elevation={0}>
                <Typography variant='h4' gutterBottom>
                  {detailContent.spaces}
                </Typography>
                {isSpaceView && (
                  <Suspense
                    fallback={
                      <div className='slekton-flex'>
                        {Array.from({ length: 3 }, (_, index) => (
                          <Skeleton
                            key={index}
                            component='div'
                            variant='text'
                            height={350}
                            width={'33%'}
                          />
                        ))}
                      </div>
                    }
                    mode='concurrent'
                  >
                    <SpacesList className='el-spaceDetail' data={Spaces} />
                  </Suspense>
                )}
              </Paper>
            )}

            {/* villa map anc nearist links section */}
            {Address && (
              <Paper ref={addressRef} className='paperDetail' elevation={0}>
                <Typography variant='h4' gutterBottom>
                  {detailContent.location}
                </Typography>
                {isAddressView && (
                  <Suspense
                    fallback={<div>{progress.loading}</div>}
                    mode='concurrent'
                  >
                    <Location
                      className='el-locationDetails'
                      link={Address.google_map_embed_link}
                      data={filteredPageData(Page?.Sections, 'NEAREST_PLACES')}
                    />
                  </Suspense>
                )}
              </Paper>
            )}

            {/* villa faq section */}
            {Page?.FAQs && (
              <Paper ref={faqRef} className='paperDetail' elevation={0}>
                <Typography variant='h4' gutterBottom>
                  {detailContent.fAQs}
                </Typography>
                {isFaqView && (
                  <Suspense
                    fallback={Array.from({ length: 4 }, (_, index) => (
                      <div key={`faqSleketon${index}`}>
                        <Skeleton component='h4' height={40} variant='text' />
                        {Array.from({ length: 4 }, (_, index) => (
                          <div key={`faqinner${index}`}>
                            <Skeleton
                              height={20}
                              component='p'
                              variant='text'
                            />
                            <Skeleton
                              height={15}
                              component='p'
                              variant='text'
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                    mode='concurrent'
                  >
                    <FaqsList className='el-faqDetails' data={Page?.FAQs} />
                  </Suspense>
                )}
              </Paper>
            )}

            {/* villa reviews section */}
            {ChannelReviews && (
              <Paper ref={ratingRef} className='paperDetail' elevation={0}>
                <Typography variant='h4' gutterBottom>
                  {detailContent.Rating}
                </Typography>
                {isRatingView && (
                  <Suspense
                    fallback={<div>{progress.loading}</div>}
                    mode='concurrent'
                  >
                    <Rating className='el-ratingDetail' data={ChannelReviews} />
                  </Suspense>
                )}
              </Paper>
            )}
            {TripAdvisorReviews && trip_advisor_location_id && (
              <Paper ref={reviewsRef} className='paperDetail' elevation={0}>
                {isReviewsView && (
                  <Suspense
                    fallback={<div>{progress.loading}</div>}
                    mode='concurrent'
                  >
                    <Reviews
                      locationId={trip_advisor_location_id}
                      data={TripAdvisorReviews}
                      className='EL-reviewsPDP'
                    />
                  </Suspense>
                )}
              </Paper>
            )}
          </Grid>

          {/*===== RIGHT SECTION =====*/}
          {!isMobile ? (
            <Grid item xs={12} sm={4}>
              <div className={`stickyRight ${isSticky ? 'sticky' : ''}`}>
                <div className='detail-right-stickBox'>
                  {/* Price Detail section */}
                  <div className='EL-rightTop'>
                    <Paper elevation={0} className='rightSection'>
                      <Suspense
                        fallback={
                          <Skeleton component='h1' variant='text' height={50} />
                        }
                        mode='concurrent'
                      >
                        <PropertyPrice
                          className='el-priceDetail'
                          fetchingUpdatingPricing={fetchingUpdatingPricing}
                          data={parseFloat(updatedAmount).toFixed(2)}
                          loader={loader}
                        />
                      </Suspense>
                    </Paper>

                    {/* Heigh Demand */}
                    {booking_count && booking_count > 8 ? (
                      <Suspense
                        fallback={
                          <Skeleton component='h1' variant='text' height={50} />
                        }
                        mode='concurrent'
                      >
                        <Demand bookingCount={booking_count} />
                      </Suspense>
                    ) : (
                      ''
                    )}

                    {/* Checkin Checkout Date range picker */}
                    <CheckinCheckoutDetail
                      id={id}
                      pathname={pathName}
                      max_occupancy={max_occupancy}
                      standard_guests={standard_guests}
                      isbookNow={true}
                      bookingId={bookingId}
                      setIsModifyClicked={setIsModifyClicked}
                      totalPayableAmount={totalPayableAmount}
                      max_children={max_children}
                      max_adults={max_adults}
                      gettingUpdatedPricing={gettingUpdatedPricing}
                      staahMapping={staahMapping}
                    />
                  </div>
                  <div className='EL-rightBottom'>
                    {/* Offers Section */}
                    <Typography variant='h4' gutterBottom>
                      {`Offers (${gettingCouponList.length})`}
                    </Typography>
                    <Paper
                      ref={offersRef}
                      className='El-couponDetail'
                      elevation={0}
                    >
                      <Suspense
                        fallback={<CouponSkeleton listLength={1} />}
                        mode='concurrent'
                      >
                        {/* list one coupon */}
                        {isOfferView && (
                          <CouponList
                            applyAction={false}
                            moreDetailAction='modal'
                            isSlider={false}
                            propertyId={property_id}
                            cardCount={1}
                            allOffersList={true}
                            skeletonLength={1}
                            id={id}
                          />
                        )}
                      </Suspense>
                    </Paper>

                    <div ref={offersRef}>
                      {isOfferView && (
                        <>
                          <Button
                            className='EL-dlCouponView'
                            onClick={handleModalOpen}
                            fullWidth
                            color='secondary'
                          >
                            View All
                          </Button>
                          {/* coupon and bank offer modal */}
                          <CouponListModal
                            openCouponsList={openCouponsList}
                            setOpenCouponsList={setOpenCouponsList}
                            propertyId={property_id}
                            skeletonLength={1}
                            id={id}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          ) : (
            <Paper ref={bottomNavigationRef} elevation={0}>
              {/* Bottom Menu Section */}
              {isBottomNavigationView && (
                <Suspense
                  fallback={
                    <Skeleton component='div' variant='text' height={80} />
                  }
                  mode='concurrent'
                >
                  <BottomNavigationCAT
                    id={id}
                    className='el-bottomNavigation'
                    fetchingUpdatingPricing={fetchingUpdatingPricing}
                    loader={loader}
                    price={updatedAmount}
                    totalPayableAmount={totalPayableAmount}
                    mmtLink={propertyChannelLink(
                      PropertyChannels,
                      'MakeMyTrip'
                    )}
                    gettingUpdatedPricing={gettingUpdatedPricing}
                    pathname={pathName}
                    max_occupancy={max_occupancy}
                    standard_guests={standard_guests}
                    setIsModifyClicked={setIsModifyClicked}
                    max_children={max_children}
                    max_adults={max_adults}
                    staahMapping={staahMapping}
                  />
                </Suspense>
              )}
            </Paper>
          )}
        </Grid>
      </div>
    </Container>
  );
};
