import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you use React Router for navigation
import './breadcrumbs.scss';

const Breadcrumb = ({ routes, className }) => {
  return (
    <Breadcrumbs
      className={`${className} EL-breadcrumbs`}
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {routes.map((route, index) => {
        const last = index === routes.length - 1;
        return last ? (
          <Typography color='textPrimary' key={route.path}>
            {route.title}
          </Typography>
        ) : (
          <Link to={route.path} key={route.path}>
            {route.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
