import { Card, Discount } from '@assets';
import CircularProgress from '@components/CircularProgressBar/Progress';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { redirectToWhatsApp } from '@utils/common';
import Svg from '@components/Common/Svg';
import {
  Alert,
  Button,
  Chip,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  contact,
  dateFormat,
  detailContent,
  progress,
  bookingInfo,
} from '@utils/customText';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '@components/Common/Analytics';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../CheckinCheckoutField/chekinCheckout.scss';
import './bookNow.scss';
// customhooks

// js
import { calculateMinimumStay, pricingRefactored } from '@utils/common';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

export default function BookNow(props) {
  const { trackEvent } = useAnalytics();
  const {
    id,
    handleCheckInDateClick,
    fetchingUpdatingPricing,
    handleBookedNow,
    bookingId,
    isBookingSuccessStatus,
    loader,
    checkInDate,
    checkOutDate,
    totalPayableAmount,
    gettingUpdatedPricing,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showAvailabilty, utmCampaign, utmSource } = useSelector(
    (state) => state.glob
  );
  const { minimumStay } = useSelector((state) => state.property);

  const navigate = useNavigate();

  const startDate = dayjs(checkInDate, dateFormat.ddmmyy);
  const endDate = dayjs(checkOutDate, dateFormat.ddmmyy);
  const millisecondsDiff = endDate?.valueOf() - startDate?.valueOf();
  const numberOfNights = Math.ceil(millisecondsDiff / (24 * 60 * 60 * 1000));

  const [minimumDays, setMinimumDays] = useState(0);

  const handleWhatsAppClick = () => {
    trackEvent('Reach Us Click', {
      checkInDate: checkInDate,
      checkOutDate: checkOutDate,
      property_id: id,
      booking_id: bookingId,
      numberOfNights: numberOfNights,
      whatsapp_number: contact?.whatsApp,
      utmCampaign: utmCampaign,
      utmSource: utmSource,
    });
    redirectToWhatsApp(utmCampaign, utmSource);
  };

  const handleClick = () => {
    if (
      (fetchingUpdatingPricing || bookingId) &&
      numberOfNights >= minimumDays
    ) {
      trackEvent('Book Now Click', {
        checkInDate: checkInDate,
        checkOutDate: checkOutDate,
        // numberOfGuests: parsedFilterValues?.numberOfGuests,
        // numberOfChildren: parsedFilterValues?.numberOfChildren,
        property_id: id,
        booking_id: bookingId,
        numberOfNights: numberOfNights,
        utmCampaign: utmCampaign,
        utmSource: utmSource,
      });
      // Handle booked now action
      handleBookedNow();
    } else {
      // Handle check availability action
      handleCheckInDateClick();
      //document.activeElement.blur();
    }
  };

  const handleCallClick = () => {
    // Use the tel: URI scheme to initiate a phone call
    window.location.href = `tel:${contact.number}`;
  };

  useEffect(() => {
    if (isBookingSuccessStatus) {
      // navigate(`/booking?id=${bookingId}&propertyId=${id}`);
      window.location.href = `/booking?id=${bookingId}&propertyId=${id}`;
    }
  }, [isBookingSuccessStatus]);

  useEffect(() => {
    const MinStays = calculateMinimumStay(
      checkInDate,
      checkOutDate,
      minimumStay
    );
    setMinimumDays(MinStays);
  }, [checkInDate, checkOutDate]);

  const whatsAppButton = (
    <Button
      disableElevation
      color='success'
      size='large'
      variant='contained'
      className='El-whatsAppDetail'
      startIcon={<WhatsAppIcon />}
      target='_blank'
      onClick={handleWhatsAppClick}
    >
      {!isMobile ? detailContent?.reachUs : ''}
    </Button>
  );

  let defaultPaymentTerm = null;
  const jsonData =
    gettingUpdatedPricing?.PaymentTerms ||
    gettingUpdatedPricing?.Property?.PaymentTerms;
  if (jsonData) {
    defaultPaymentTerm = pricingRefactored(jsonData);
  }
  const formattedPaymentTerm = defaultPaymentTerm?.name.replace(/\s+/g, '');

  return (
    <div>
      <>
        {(fetchingUpdatingPricing || bookingId) &&
        (isNaN(numberOfNights) ||
          (numberOfNights >= minimumDays &&
            numberOfNights < bookingInfo.maxNight)) ? (
          <Alert
            icon={<Svg src={Card} className='EL-cardSptlit' alt='card' />}
            severity='warning'
            size='small'
            className='El-bookAtSptlit'
          >
            {`Reserve now with a ${formattedPaymentTerm} payment, pay the rest later`}
          </Alert>
        ) : numberOfNights < minimumDays ? (
          <Alert
            severity='error'
            variant='standard'
            className={'EL-minStayAlert'}
          >
            Please select at least {minimumDays} nights
          </Alert>
        ) : (
          ''
        )}

        {isNaN(numberOfNights) || numberOfNights < bookingInfo.maxNight ? (
          <div className='flex ai-center sp-bw EL-caontactUsPDP'>
            {(fetchingUpdatingPricing || bookingId) &&
              numberOfNights >= minimumDays &&
              whatsAppButton}
            <Button
              disableElevation
              fullWidth
              className={
                (fetchingUpdatingPricing || bookingId) &&
                numberOfNights >= minimumDays
                  ? 'EL-BookNow'
                  : 'EL-CheckAvailability'
              }
              disabled={loader || showAvailabilty}
              size='large'
              variant='contained'
              color={
                fetchingUpdatingPricing || bookingId ? 'secondary' : 'primary'
              }
              startIcon={
                (loader || showAvailabilty) && (
                  <CircularProgress fetching={loader || showAvailabilty} />
                )
              }
              onClick={() => handleClick()}
            >
              {loader
                ? progress.checking
                : (fetchingUpdatingPricing || bookingId) &&
                  numberOfNights >= minimumDays
                ? `${detailContent.reserveNow} ${formattedPaymentTerm}`
                : !showAvailabilty
                ? detailContent.checkAvailability
                : 'Checking Availability ...'}
            </Button>
          </div>
        ) : (
          <>
            <div className='EL-maxDayError EL-alert'>
              <Alert icon={<img src={Discount} />} severity='error'>
                {detailContent.maxBookingNights}
              </Alert>
            </div>
            <div className='flex ai-center sp-bw EL-caontactUsPDP'>
              {whatsAppButton}
              {isMobile && (
                <Button
                  disableElevation
                  fullWidth
                  className='EL-BookNow'
                  size='large'
                  variant='contained'
                  color={'secondary'}
                  startIcon={<CallOutlinedIcon />}
                  onClick={handleCallClick}
                >
                  {detailContent.contactUs}
                </Button>
              )}
            </div>
            {!isMobile && (
              <div className='EL-contactUsPDP'>
                <Divider>{detailContent?.or}</Divider>
                <Chip
                  label={
                    <>
                      {detailContent?.callUs} <b>{contact.number}</b>
                    </>
                  }
                  variant='outlined'
                />
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}
