import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CouponView from './couponView';
import './card.scss';
import { appliedCouponId } from '@reducers/couponReducer';
import { useElicashUpdate } from '@reducers/globalReducers';

export default function CouponCard(props) {
  const dispatch = useDispatch();
  const {
    id,
    title,
    description,
    couponCode,
    tnc,
    couponIcon,
    applyAction,
    moreDetailAction,
    fullView,
    is_active,
    disabled_message,
  } = props;
  const [openCoupon, setOpenCoupon] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { loader } = useSelector((state) => state.glob);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleModalClick = () => {
    setOpenCoupon(true);
  };

  const handleApplyCoupon = () => {
    if (!loading && is_active) {
      // Only dispatch the action if not already loading and coupon is active
      setLoading(true); // Set loading to true for the clicked coupon
      dispatch(useElicashUpdate(0));
      dispatch(appliedCouponId(id))?.then(() => {
        setLoading(false); // Reset loading to false when the action is completed
      });
    }
  };

  return (
    <>
      <Card className='EL-CouponCard'>
        <div className='EL-CouponCardInner'>
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: 'GrayText' }}
                size='lg'
                aria-label='coupon'
                src={couponIcon}
              />
            }
            action={
              applyAction ? (
                <Box
                  sx={{
                    m: 1,
                    position: 'relative',
                    cursor: !is_active ? 'not-allowed' : 'pointer',
                  }}
                >
                  <Button
                    aria-label='add to favorites'
                    onClick={handleApplyCoupon}
                    disabled={!is_active || loading}
                  >
                    {loading && (
                      <CircularProgress
                        sx={{
                          color: 'green',
                        }}
                      />
                    )}
                    Apply
                  </Button>
                </Box>
              ) : (
                ''
              )
            }
            title={title}
          />

          <CardContent>
            <Typography variant='h6' color='primary'>
              {couponCode}
            </Typography>
            {disabled_message && (
              <Typography variant='body1'>{disabled_message}</Typography>
            )}
            <Typography
              variant='body2'
              color='text.secondary'
              dangerouslySetInnerHTML={{ __html: description }}
            />

            <Collapse
              in={fullView ? true : expanded}
              timeout='auto'
              unmountOnExit
              className='EL-CollapsTnC'
            >
              <List className='EL-CouponTnC'>
                {tnc &&
                  tnc?.map((item, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemIcon>
                        <CheckCircleRoundedIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
              </List>
            </Collapse>
            {!fullView && (
              <CardActions disableSpacing>
                <Button
                  onClick={
                    moreDetailAction === 'expand'
                      ? handleExpandClick
                      : handleModalClick
                  }
                  aria-label='add to favorites'
                >
                  {expanded ? 'Less details' : 'More details'}
                </Button>
              </CardActions>
            )}
          </CardContent>
        </div>
      </Card>

      <CouponView
        setOpenCoupon={setOpenCoupon}
        openCoupon={openCoupon}
        coupon={props}
      />
    </>
  );
}
