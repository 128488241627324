import { Alert, AlertTitle, IconButton, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

const ApiError = (props) => {
  const { error, errorMessage } = props;
  const [apiErrorModal, setApiErrorModal] = useState(false);

  useEffect(() => {
    setApiErrorModal(error ? true : false);
  }, []);

  const handleErrorModalClose = () => {
    setApiErrorModal(false);
  };

  return (
    <>
      <Snackbar
        open={apiErrorModal}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: 'bottom', vertical: 'center' }}
        onClose={handleErrorModalClose}
      >
        <Alert severity='error'>
          <AlertTitle sx={{ position: 'relative' }}>
            Error{' '}
            <IconButton
              aria-label='close'
              color='inherit'
              sx={{ p: 0.5, position: 'absolute', right: 0 }}
              onClick={handleErrorModalClose}
            >
              <CloseIcon />
            </IconButton>
          </AlertTitle>
          {errorMessage} -<strong>check it out!</strong>{' '}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ApiError;
