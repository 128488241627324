import {
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Button,
} from '@mui/material';
import Svg from '@components/Common/Svg';
import React, { useState } from 'react';
import { BookingNotBelong } from '@assets';
import './errorDialog.scss';
export const ErrorDialog = (props) => {
  const {
    title,
    ctaText, // Add the name prop
    setOpenDialog,
    openDialog,
    ctaAction,
    href,
    onClose,
  } = props;

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={onClose && handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='EL-bookingnotBlongModal'
    >
      <DialogContent className='EL-bookingnotBlongContent'>
        <DialogContentText id='alert-dialog-description'>
          <Svg src={BookingNotBelong} alt='' />
          <Typography variant='h6' gutterBottom>
            {title}
          </Typography>
          {href && (
            <Button
              href={href}
              disableElevation
              fullWidth
              color='secondary'
              size='large'
              variant='contained'
              className='EL-backHeader'
            >
              {ctaText}
            </Button>
          )}
          {ctaAction && (
            <Button
              onClick={handleClose}
              disableElevation
              fullWidth
              color='secondary'
              size='large'
              variant='contained'
              className='EL-backHeader'
            >
              {ctaText}
            </Button>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
