import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import useAnalytics from '@components/Common/Analytics';
import { contact } from '@utils/customText';
import Svg from '@components/Common/Svg';
import { Badge, Fab, useMediaQuery, useTheme } from '@mui/material';

import { redirectToWhatsApp } from '@utils/common';
import { useSelector } from 'react-redux';
import React from 'react';
import { Whatsapp, CallIcon } from '@assets';
import './whatsApp.scss';

export const FloatingWhatsApp = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { trackEvent } = useAnalytics();
  const { utmCampaign, utmSource } = useSelector((state) => state?.glob);
  const {} = props;

  const handleWhatsAppClick = () => {
    trackEvent('Reach Us float', {
      utmCampaign: utmCampaign,
      utmSource: utmSource,
    });
    redirectToWhatsApp(utmCampaign, utmSource);
  };

  return (
    <>
      <Fab
        className='EL-floatAction EL-CallIcon'
        size='large'
        color='inherit'
        aria-label='add'
        target='_blank'
        onClick={handleWhatsAppClick}
      >
        <Svg src={Whatsapp} className='EL-elicashIcon' alt='Elicash' />
        {!isMobile && (
          <Badge
            color='error'
            overlap='circular'
            badgeContent=' '
            variant='dot'
          >
            <div className='ringring'></div>
          </Badge>
        )}
      </Fab>
      {isMobile && (
        <Fab
          className='EL-floatAction EL-whatsappIcon'
          size='large'
          color='inherit'
          aria-label='add'
          target='_blank'
          href={`tel:${contact.number}`}
        >
          <Svg src={CallIcon} className='EL-elicashIcon' alt='Elicash' />
        </Fab>
      )}
    </>
  );
};
