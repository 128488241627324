import TagManager from 'react-gtm-module';

export const initializeGTM = (pageTitle, pageType) => {
  TagManager.initialize({ gtmId: process.env.GTM_ID });

  // Push page title and type to the data layer
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageLoad',
      pageTitle,
      pageType,
    },
  });
};

export const trackGTMEvent = (eventName, eventData) => {
  TagManager.dataLayer({ dataLayer: { event: eventName, ...eventData } });
};

export const eventClick = (eventName, eventData) => {
  trackGTMEvent(eventName, eventData);
  // Additional logic or processing specific to the event handling
};

// clarityTracking.js
export const initializeClarityTracking = () => {
  // Clarity tracking code
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = true;
    t.src = `https://www.clarity.ms/tag/${i}`;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', process.env.CLARITY_ID);
};

export const initializeHubspotTracking = (hubspotId) => {
  // console.log(`Initialized HubSpot Tracking with ${hubspotId}`);
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = `//js.hs-scripts.com/${hubspotId}.js`;
  document.head.appendChild(script);
  return () => {
    // Cleanup when component is unmounted
    document.head.removeChild(script);
  };
};
