import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  useMediaQuery,
  useTheme,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import React, { useState } from 'react';

export const LinkItem = (props) => {
  const { title, data, accordion, panel, expanded, handleChange } = props;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return !accordion ? (
    <nav aria-label='secondary mailbox folders'>
      <List
        className='EL-footerLinks'
        subheader={<ListSubheader component='h6'>{title}</ListSubheader>}
      >
        {data?.map((item, index) => (
          <ListItem key={`footerNave${index}`} disablePadding>
            <ListItemButton href={item?.url}>
              <ListItemText primary={item?.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </nav>
  ) : (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      //   defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownOutlinedIcon />}
        aria-controls={`${panel}bh-content`}
        id={`${panel}bh-header`}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <nav aria-label='secondary mailbox folders'>
          <List className='EL-footerLinks'>
            {data?.map((item, index) => (
              <ListItem key={`footerNave${index}`} disablePadding>
                <ListItemButton href={item?.url}>
                  <ListItemText primary={item?.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </AccordionDetails>
    </Accordion>
  );
};
