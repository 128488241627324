import { Skeleton as MuiSkeleton } from '@mui/material';
import React from 'react';
import './skeleton.scss';
const Skeleton = ({ variant, animation, width, height, ...restProps }) => {
  return (
    <MuiSkeleton
      variant={variant}
      animation={animation}
      style={{ width, height }}
      {...restProps}
    />
  );
};

export default Skeleton;
