// Common/Analytics.js
import { useEffect } from 'react';
const writeKey = process.env.SEGMENT_KEY; // Replace 'YOUR_WRITE_KEY' with your Segment write key

const useAnalytics = () => {
  useEffect(() => {
    // Load analytics.js script dynamically
    const script = document.createElement('script');
    script.src = `https://cdn.segment.com/analytics.js/v1/${writeKey}/analytics.min.js`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize analytics instance
    window.analytics = window.analytics || [];
    if (!window.analytics.initialize && window.analytics.load) {
      window.analytics.invoked = true;
      window.analytics.load(writeKey);
      window.analytics.page();
    }

    // Clean up function
    return () => {
      delete window.analytics;
    };
  }, [writeKey]);

  // Expose trackEvent function
  const trackEvent = (eventName, eventData) => {
    window?.analytics?.track && window?.analytics?.track(eventName, eventData);
  };

  // Return trackEvent function
  return { trackEvent };
};

export default useAnalytics;
