import { LogoFooterwhite, Logowhite } from '@assets';
import { FloatingWhatsApp } from '@components/Common/WhatsApp';
import {
  Box,
  Container,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { footerSections } from '@reducers/propertyReducer';
import { campaignUpdate } from '@reducers/globalReducers';
import { useInView } from 'react-intersection-observer';
import React, { useEffect } from 'react';
import { CONSTANT_PAGES_URL } from '@utils/constants';
import dataJson from '../../data.js';
import { FooterLinkGroup } from './FooterLinks';
import './footer.scss';
import Svg from '@components/Common/Svg';
import { SocialMediaButtonGroup } from './SocialLinks';
import { useSelector, useDispatch } from 'react-redux';

export const Footer = () => {
  const dispatch = useDispatch();
  const [footerRef, isFooterView] = useInView({
    triggerOnce: true,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pagesFooterSectionsData } = useSelector((state) => state.property);

  useEffect(() => {
    const { nameSpace, slug } = CONSTANT_PAGES_URL.footer;
    dispatch(footerSections(nameSpace, slug));
  }, []);

  // Extract utm_source from the URL
  const getUtmSource = localStorage.getItem('utm_source');

  // Retrieve utm_campaign from local storage
  const getUtmCampaign = localStorage.getItem('utm_campaign');

  useEffect(() => {
    dispatch(campaignUpdate(getUtmCampaign, getUtmSource));
  }, [getUtmSource, getUtmCampaign]);

  return (
    <Box
      className='elFooterMain'
      sx={{
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
      }}
      ref={footerRef}
    >
      {isFooterView && (
        <>
          <Container maxWidth='lg'>
            <Grid container columns={16}>
              <Grid item md={6} xs={16}>
                <Svg
                  className='EL-footerLogowhite'
                  src={Logowhite}
                  alt='logo footer'
                />
                <Typography
                  className='el-footerInfo'
                  variant='string'
                  component='p'
                >
                  Stands at the forefront of the hospitality scene, curating
                  luxury villa vacations in coveted global destinations
                </Typography>
                {pagesFooterSectionsData?.social && (
                  <SocialMediaButtonGroup
                    data={pagesFooterSectionsData?.social}
                  />
                )}
              </Grid>
              <Grid className='el-footerLinks' item md={10} xs={16}>
                <FooterLinkGroup data={pagesFooterSectionsData} />
              </Grid>
            </Grid>
          </Container>
          <Container disableGutters className='EL-footerBottom' maxWidth='xl'>
            <Svg src={LogoFooterwhite} alt='logo footer' />{' '}
            <span>
              © Copyright <b>Ishanee Villas Tech Private Limited</b>. All Rights
              Reserved
            </span>
          </Container>
        </>
      )}
      <FloatingWhatsApp />
    </Box>
  );
};
