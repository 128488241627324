import { fetchingUpdatedPricing } from '@actions/propertiesAction';
import useBookingDetails from '@customhooks/useBookingDetails';

import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { updateTheCalender } from '@reducers/propertyReducer';
import { calculateMinimumStay, updateCalenderList } from '@utils/common';
import { DATE_PICKER_HEADERS } from '@utils/constants';
import { dateFormat } from '@utils/customText';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DateRangePicker from './DateRange';
import './datePickerDrawer.scss';
import './dateRangeOptions.scss';

const DatePickerDrawer = ({
  setCheckInDate,
  setCheckOutDate,
  setIsClearDatesModal,
  checkOutDate,
  checkInDate,
  firstDate,
  availabilityCalenderList,
  calenderModalError,
  setCalenderModalError,
  setIsCheckInDatePickerOpen,
  isCheckInDatePickerOpen,
  clearDatesModal,
  isGuestDrawer,
  handleDatePickerModal,
  isModifyClicked,
  setIsModifyClicked,
  setIsOuterClicked,
}) => {
  const { totalNumberOfNights, datePickerHeader, setDatePickerHeader } =
    useBookingDetails();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [rerenderKey, setRerenderKey] = useState(0); // Introduce a state for the key
  const [minimumDays, setMinimumDays] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location?.search);
  const bookingId = searchParams.get('bookingId');
  const parts = location.pathname.split('/');
  const slug = parts[2];
  const isDetailPage =
    (location.pathname.includes('villas-in') && parts.length == 3) ||
    (searchParams.has('propertyId') && location.pathname.includes('villas-in'));

  const { minimumStay } = useSelector((state) => state.property);
  // const copyAvailabilityCalenderList = useSelector(
  //   (state) => state?.property?.copyOfavailabilityDates
  // );
  const propertyId = searchParams.get('propertyId');
  const copyAvailabilityCalenderList = JSON.parse(
    sessionStorage.getItem('copyData')
  );

  const [selectDate, setSelectDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(), // Adding 1 day (24 hours) to firstDate
      key: 'selection',
    },
  ]);

  const handleSnackbarClose = () => {
    setCalenderModalError(false);
  };
  const handleDrawerClose = () => {
    setIsCheckInDatePickerOpen(false);
    setIsModifyClicked(false);
  };
  useEffect(() => {
    if (
      checkInDate &&
      checkOutDate &&
      dayjs(checkOutDate).isAfter(dayjs(checkInDate).add(1, 'day'))
    ) {
      setCalenderModalError(false);
    } else if (dayjs(checkOutDate).isAfter(dayjs(checkInDate).add(2, 'day'))) {
      setCalenderModalError(false);
    }
  }, [checkInDate, checkOutDate, clearDatesModal]);

  const handleMenuOpen = (event) => {
    setIsCheckInDatePickerOpen(false);
  };

  const handleClearDates = () => {
    setSelectedStartDate(null);
    setSelectDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    setCheckInDate('');
    setCheckOutDate('');
    setIsClearDatesModal(true);
    dispatch(fetchingUpdatedPricing(false));
    dispatch(updateTheCalender(copyAvailabilityCalenderList));

    // Change the key to trigger re-render
    setRerenderKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (checkInDate && checkOutDate && !isGuestDrawer) {
      const nextAvailableDate = dayjs(firstDate)
        .add(1, 'day')
        .format('YYYY-MM-DD'); // Format it to match the format of firstDate

      const formattedCheckInDate = !checkInDate
        ? firstDate
        : dayjs(checkInDate, 'DD MMM,YYYY').format('YYYY-MM-DD');
      const formattedCheckOutDate = !checkOutDate
        ? nextAvailableDate
        : dayjs(checkOutDate, 'DD MMM,YYYY').format('YYYY-MM-DD');
    }
  }, [isGuestDrawer, selectDate]);

  useEffect(() => {
    if (dayjs(checkOutDate).isSame(dayjs(checkInDate).add(0, 'day'), 'day')) {
      setSelectDate([
        {
          startDate: new Date(),
          endDate: new Date(), // Adding 1 day (24 hours) to firstDate
          key: 'selection',
        },
      ]);
      setCheckOutDate('');
      setCheckInDate('');
      dispatch(fetchingUpdatedPricing(false));
    } else if (
      (location.pathname.startsWith('/villas') || location.pathname === '/') &&
      checkInDate &&
      checkOutDate
    ) {
      setSelectDate([
        {
          startDate: new Date(checkInDate),
          endDate: new Date(checkOutDate),
          key: 'selection',
        },
      ]);
    }
  }, [!isCheckInDatePickerOpen]);

  useEffect(() => {
    if (
      location.pathname === '/booking' &&
      checkInDate &&
      checkOutDate &&
      !isCheckInDatePickerOpen
    ) {
      const startDate = dayjs(checkInDate);
      const endDate = dayjs(checkOutDate);

      if (startDate.isValid() && endDate.isValid()) {
        const startDateFormatted = startDate.format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );
        const endDateFormatted = endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        setSelectDate([
          {
            startDate: new Date(startDateFormatted),
            endDate: addDays(new Date(startDateFormatted), totalNumberOfNights),
            key: 'selection',
          },
        ]);
      } else {
        console.error('Invalid date values');
      }
    }
  }, [location, checkInDate, checkOutDate]);

  useEffect(() => {
    const minStays = calculateMinimumStay(
      checkInDate,
      checkOutDate,
      minimumStay
    );
    setMinimumDays(minStays);

    if (!checkInDate) {
      setDatePickerHeader(DATE_PICKER_HEADERS.checkInDateHeader);
    } else if (checkInDate === checkOutDate) {
      setDatePickerHeader(DATE_PICKER_HEADERS.checkOutDateHeader);
    } else if (checkInDate && checkOutDate && checkInDate !== checkOutDate) {
      setDatePickerHeader(
        `${numberOfNights ? numberOfNights : 0} nights selected`
      );
    }

    if (
      bookingId &&
      (location.pathname.startsWith('/booking') || isDetailPage)
    ) {
      const startDate = dayjs(checkInDate);
      const endDate = dayjs(checkOutDate);

      if (startDate.isValid() && endDate.isValid()) {
        const startDateFormatted = startDate.format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );
        const endDateFormatted = endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        setSelectDate([
          {
            startDate: new Date(startDateFormatted),
            endDate: addDays(new Date(endDateFormatted), totalNumberOfNights),
            key: 'selection',
          },
        ]);
      }
    } else if (
      isDetailPage &&
      !bookingId &&
      !location.pathname.startsWith('/booking')
    ) {
      const startDate = dayjs(checkInDate);
      const endDate = dayjs(checkOutDate);
      if (startDate.isValid() && endDate.isValid()) {
        const startDateFormatted = startDate.format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );
        const endDateFormatted = endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        setSelectDate([
          {
            startDate: new Date(startDateFormatted),
            endDate: addDays(new Date(endDateFormatted), totalNumberOfNights),
            key: 'selection',
          },
        ]);
      }
    }
  }, [checkInDate, checkOutDate, open]);
  const startDate = dayjs(checkInDate, dateFormat.ddmmyy);
  const endDate = dayjs(checkOutDate, dateFormat.ddmmyy);

  // Calculate the difference in milliseconds
  const millisecondsDiff = endDate?.valueOf() - startDate?.valueOf();

  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)

  const numberOfNights = Math.ceil(millisecondsDiff / (24 * 60 * 60 * 1000));
  useEffect(() => {
    const currentDate = new Date();
    if (
      selectDate[0].startDate.toDateString() !== currentDate.toDateString() &&
      selectDate[0].endDate.toDateString() !== currentDate.toDateString()
    ) {
      const startDateFormatted = dayjs(selectDate[0].startDate).format(
        dateFormat.ddmmyy
      );
      const endDateFormatted = dayjs(selectDate[0].startDate).format(
        dateFormat.ddmmyy
      );

      if (propertyId || slug) {
        dispatch(updateTheCalender(copyAvailabilityCalenderList));
      }
      updateCalenderList(
        startDateFormatted,
        endDateFormatted,
        availabilityCalenderList,
        copyAvailabilityCalenderList
      );
    }
  }, [selectDate, isCheckInDatePickerOpen, propertyId, slug]);
  return (
    <Drawer
      anchor={'bottom'}
      open={isCheckInDatePickerOpen}
      onClose={
        (clearDatesModal && !selectDate.startDate && !selectDate.endDate) ||
        isNaN(numberOfNights) ||
        numberOfNights < minimumDays
          ? () => {
              setIsCheckInDatePickerOpen(false);
            }
          : () => {
              setIsCheckInDatePickerOpen(false);
              setIsModifyClicked(false);
              setIsOuterClicked(true);
            }
      }
      className='mobileDatePickerModal'
    >
      <DialogTitle id='alert-dialog-title' className='dialog-title'>
        <Typography variant='body1' className='el-dialog-header'>
          {datePickerHeader}
        </Typography>

        <IconButton
          aria-label='close'
          className='icon-button'
          onClick={handleDrawerClose}
        >
          <CloseIcon style={{ width: '0.8em' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DateRangePicker
          setCheckInDate={setCheckInDate}
          setCheckOutDate={setCheckOutDate}
          months={7}
          direction='vertical'
          availabilityCalenderList={availabilityCalenderList}
          setSelectDate={setSelectDate} // Pass the setter function
          selectDate={selectDate} // Pass the selected date range
          setIsClearDatesModal={setIsClearDatesModal}
          copyAvailabilityCalenderList={copyAvailabilityCalenderList}
          clearDatesModal={clearDatesModal}
          key={rerenderKey}
        />

        {numberOfNights < minimumDays && checkOutDate !== checkInDate && (
          <div className='EL-calenderError EL-alert'>
            <Alert variant='standard' severity='error'>
              Please select at least {minimumDays} nights
            </Alert>
          </div>
        )}
      </DialogContent>

      <DialogActions className='El-action-container'>
        <div className='El-actions'>
          <div className='El-selectedDates'>
            <div className='EL-dateContainer'>
              <Typography variant='subtitle2' className='date-header'>
                Check-in
              </Typography>
              <Typography
                variant='subtitle1'
                className={checkInDate ? 'date-selected' : 'date-empty'}
              >
                {checkInDate ? checkInDate : 'Add Date'}
              </Typography>
            </div>
            <div className='EL-dateContainer'>
              <Typography variant='subtitle2' className='date-header'>
                Check-out
              </Typography>

              <Typography
                variant='subtitle1'
                className={
                  checkOutDate && checkOutDate != checkInDate
                    ? 'date-selected'
                    : 'date-empty'
                }
              >
                {checkOutDate && checkOutDate != checkInDate
                  ? checkOutDate
                  : 'Add Date'}
              </Typography>
            </div>
          </div>

          <div className='el-nights'>
            {/* <Typography variant='button' display='block' gutterBottom>
            {numberOfNights ? numberOfNights : 0} Nights
          </Typography>
          <Typography variant='caption' display='block' gutterBottom>
            Selected
          </Typography> */}
          </div>

          <div className='button-wrapper'>
            <Button
              variant='text'
              className='clear-button'
              disableElevation
              disabled={!checkInDate && !checkOutDate}
              onClick={handleClearDates}
            >
              Clear Dates
            </Button>
            <Button
              size='large'
              variant='contained'
              className='guest-button'
              disableElevation
              onClick={handleDatePickerModal}
              disabled={
                (clearDatesModal &&
                  !selectDate.startDate &&
                  !selectDate.endDate) ||
                isNaN(numberOfNights) ||
                numberOfNights < minimumDays
              }
            >
              Select Guest(s)
            </Button>
          </div>
        </div>
      </DialogActions>
    </Drawer>
  );
};

export default DatePickerDrawer;
