import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import './header.scss';

function MobileHeader(props) {
  const { title, url, onClick, children, showBackButton = true } = props;
  const navigate = useNavigate();

  return (
    <AppBar
      className='EL-mobileHeader'
      color='inherit'
      elevation={0}
      position='fixed'
    >
      <Toolbar>
        {/* Back Button */}
        {showBackButton && (
          <IconButton
            edge='start'
            color='inherit'
            aria-label='back'
            onClick={onClick}
          >
            <ArrowBackIcon />
          </IconButton>
        )}

        {children ? children : <Typography variant='h6'>{title}</Typography>}

        {/* Menu Button */}
        {/* {hamburger && (
          <IconButton
            edge='end'
            color='inherit'
            aria-label='menu'
            onClick={handleMenuOpen}
          >
            <MenuRoundedIcon />
          </IconButton>
        )} */}
      </Toolbar>
    </AppBar>
  );
}

export default MobileHeader;
