import { Facebook, Instagram, WhatsApp } from '@mui/icons-material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ButtonGroup, IconButton } from '@mui/material';
import React from 'react';
export const SocialMediaButtonGroup = (props) => {
  const { data } = props;
  return (
    <>
      <ButtonGroup className='el-socialLinks' aria-label='social media group'>
        {data?.map((item, index) => (
          <IconButton
            key={`socialLinks${index}`}
            color='info'
            aria-label={item?.title}
            href={item?.url}
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            {item?.title === 'Facebook' && <Facebook />}
            {item?.title === 'Instagram' && <Instagram />}
            {item?.title === 'WhatsApp' && <WhatsApp />}
            {item?.title === 'YouTube' && <YouTubeIcon />}
            {item?.title === 'LinkedIn' && <LinkedInIcon />}
          </IconButton>
        ))}
      </ButtonGroup>
    </>
  );
};
