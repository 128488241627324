import { Button } from '@mui/material';
import React from 'react';
import './cta.scss';
export const ConfirmAction = (props) => {
  const {
    variant,
    avtar,
    title,
    color,
    action,
    actionType,
    className,
    endIcon,
    target,
  } = props;

  const handleAction = () => {
    if (actionType === 'link') {
      window.open(action, '_blank');
    } else if (actionType === 'pdf') {
      const link = document.createElement('a');
      link.href = action;
      link.target = target ? target : '_blank';
      link.rel = 'noopener noreferrer';
      link.download = 'file.pdf';
      link.click();
    }
  };

  return (
    <div className='EL-confrimAction'>
      <a
        href={action}
        target={target ? target : '_blank'}
        rel='noopener noreferrer'
      >
        <Button
          fullWidth
          variant={variant}
          color={color}
          disableElevation
          size='large'
          onClick={() => handleAction}
          className={className}
          endIcon={endIcon}
        >
          {avtar}
          {title}
        </Button>
      </a>
    </div>
  );
};
