import {
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { SocialMediaButtonGroup } from './SocialLinks';
import { LinkItem } from './Links';

export const FooterLinkGroup = (props) => {
  const { data } = props;
  const theme = useTheme();
  const [expandedPanel, setExpandedPanel] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  return (
    <div className='el-footerLinkinner'>
      {!isMobile ? (
        <>
          {data?.explore && (
            <LinkItem
              defaultExpanded={true}
              title='Explore Villas'
              data={data?.explore}
            />
          )}
          {data?.company && <LinkItem title='Company' data={data?.company} />}
          {data?.legal && <LinkItem title='Legal' data={data?.legal} />}
        </>
      ) : (
        <>
          {data?.explore && (
            <LinkItem
              accordion={false}
              // defaultExpanded={true}
              title='Explore Villas'
              data={data?.explore}
            />
          )}
          {data?.company && (
            <LinkItem
              accordion={true}
              panel='panel1'
              expanded={expandedPanel === 'panel1'}
              handleChange={handleAccordionChange('panel1')}
              title='Company'
              data={data?.company}
            />
          )}
          {data?.legal && (
            <LinkItem
              accordion={true}
              panel='panel2'
              expanded={expandedPanel === 'panel2'}
              handleChange={handleAccordionChange('panel2')}
              title='Legal'
              data={data?.legal}
            />
          )}
        </>
      )}
    </div>
  );
};
