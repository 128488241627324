import { Typography } from '@mui/material';
import React from 'react';

export const DangerousHtml = (props) => {
  const { data } = props;
  return (
    <>
      <Typography gutterBottom dangerouslySetInnerHTML={{ __html: data }} />
    </>
  );
};
