import { AppBar, Container, Slide, Toolbar, Button } from '@mui/material';
import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const Login = React.lazy(() => import('@components/Login/LoginModal'));
const ElicashModal = React.lazy(() =>
  import('@components/Wallet/ElicashModal')
);
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { setOpen } from '@reducers/globalReducers';
import PropTypes from 'prop-types';
import Logo from '../Logo';

const DrawerMenu = React.lazy(() => import('./Drawer'));
import useAuth from '@customhooks/useAuth';
import { useAccessTokens } from '@customhooks/useTokens';
import { getProfileDetail } from '@reducers/profileReducer';

// sticky header scroll method
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

// sticky header scroll props
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export const Header = (props) => {
  const dispatch = useDispatch();
  const { isFirstTimeUser } = useAuth();
  const { sticky, logoColor, transparentHeader } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const { accessToken, refreshToken } = useAccessTokens();

  const { open, isOpen } = useSelector((state) => state.glob);
  // const { isFirstTimeUser } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const isHeaderScrolled = window.scrollY > 50;
      setIsScrolled(isHeaderScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (accessToken && refreshToken) {
      dispatch(getProfileDetail());
    }
  }, []);

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          component='nav'
          color='transparent'
          position={sticky ? 'fixed' : ''}
          className={
            isScrolled
              ? 'sticky-header headerMain'
              : `headerMain ${transparentHeader && 'El-transparentHeader'}`
          }
        >
          <Container maxWidth='lg'>
            <Suspense fallback={''}>
              <Login
                setOpen={setOpen}
                open={open}
                isFirstTimeUser={isFirstTimeUser}
              />
            </Suspense>
            <Toolbar className='el-headerToolbar' disableGutters>
              <Logo logoColor={isScrolled ? 'black' : logoColor} />
              <div>
                <Suspense fallback={''}>
                  <DrawerMenu />
                </Suspense>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      {/* wallet modal */}
      <Suspense fallback={''}>
        <ElicashModal isOpen={isOpen} />
      </Suspense>
      {/* wallet moda end */}
    </>
  );
};

export default Header;
