// SnackbarProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Alert, Snackbar } from '@mui/material';

const SnackbarContext = createContext();

export const SnackbarProvider = (props) => {
  const { title, status, severity } = props;
  const [snackbarState, setSnackbarState] = useState({
    open: status,
    message: title,
    severity: severity,
  });

  useEffect(() => {
    openSnackbar(title, severity);
  }, [status]);

  const openSnackbar = (message, severity = severity) => {
    setSnackbarState({ open: true, message, severity });

    setTimeout(() => {
      setSnackbarState({ ...snackbarState, open: false });
    }, 5000);
  };

  const closeSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'top' }}
      >
        <Alert
          elevation={6}
          variant='filled'
          onClose={closeSnackbar}
          severity={snackbarState.severity}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
