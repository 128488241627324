import { AppBar, Box, Container, Slide, Toolbar } from '@mui/material';
import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ApiError from '@components/Common/ApiError';
import Logo from './Logo';

// import Login from '@components/Header/LoginMobile';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import './header.scss';

const drawerWidth = '100%';

const Menu = React.lazy(() => import('./Menu/Menu'));
const Login = React.lazy(() => import('@components/Login/LoginModal'));
import useAuth from '@customhooks/useAuth';
const ElicashModal = React.lazy(() =>
  import('@components/Wallet/ElicashModal')
);
import { useAccessTokens } from '@customhooks/useTokens';
import { setOpen } from '@reducers/globalReducers';
import { getProfileDetail } from '@reducers/profileReducer';
import Cookies from 'universal-cookie';

const headerLinks = [
  { id: 1, name: 'About us', link: '/explore/about' },
  { id: 2, name: 'Our team', link: '/explore/team' },
  { id: 3, name: 'Partner with us', link: '/explore/partner' },
];
// sticky header scroll method
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

// sticky header scroll props
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const cookies = new Cookies();

export const Header = (props) => {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAccessTokens();
  const { isFirstTimeUser } = useAuth();
  const { open } = useSelector((state) => state.glob);
  const { error, errorMessage, isOpen } = useSelector((state) => state?.glob);
  const {
    sticky,
    logoColor,
    fixed,
    className,
    scrollDrop = true,
    isAbsolute,
  } = props;
  const [isScrolled, setIsScrolled] = useState(false);

  //-------------------TEMPORARY------------------
  // useEffect(() => {
  //   cookies.remove('refreshToken');
  //   cookies.remove('accessToken');
  // }, []);
  //-------------------------------------------------

  useEffect(() => {
    const handleScroll = () => {
      const isHeaderScrolled = window.scrollY > 50;
      setIsScrolled(isHeaderScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (accessToken && refreshToken) {
      dispatch(getProfileDetail());
    }
  }, [isFirstTimeUser]);

  return (
    <div id='headerTop' className={className}>
      <HideOnScroll {...props}>
        <AppBar
          component='nav'
          color='transparent'
          position={isAbsolute ? 'absolute' : sticky ? 'fixed' : ''}
          className={
            isScrolled && scrollDrop ? 'sticky-header headerMain' : 'headerMain'
          }
        >
          <Container maxWidth='lg'>
            <Suspense fallback={<div>Loading...</div>}>
              <Login setOpen={setOpen} open={open} />
            </Suspense>
            <Toolbar className='el-headerToolbar' disableGutters>
              <Logo
                height='32px'
                width='144px'
                logoColor={isScrolled && scrollDrop ? 'black' : logoColor}
              />

              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Menu navItems={headerLinks} />
                </Suspense>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>

      {/* error Handling */}
      {error && <ApiError error={error} errorMessage={errorMessage} />}

      {/* wallet modal */}
      <Suspense fallback={<div>Loading...</div>}>
        <ElicashModal isOpen={isOpen} />
      </Suspense>
      {/* wallet moda end */}
    </div>
  );
};

export default Header;
