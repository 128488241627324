import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';

import * as React from 'react';
import './matterport.scss';

const MatterportDialog = (props) => {
  const { matterportKey, setOpen, open } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      className='EL-matterportDialog'
      fullWidth
      maxWidth='lg'
    >
      <IconButton
        aria-label='close'
        onClick={handleClose}
        className='El-matterportDialogClose'
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className='EL-matterportDialogContent' dividers>
        <div className='EL-matterportDialogContentInner'>
          <iframe
            width='100%'
            src={`${matterportKey}&play=1`}
            frameborder='0'
            allowfullscreen
            allow='fullscreen; xr-spatial-tracking'
            // allow='xr-spatial-tracking'
          ></iframe>
          {/* <Button
          startIcon={<ShareIcon />}
          className='EL-matterportShare'
          autoFocus
          onClick={copyMatterportUrl}
        >
          Share
        </Button> */}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MatterportDialog;
