import React, { useEffect, useState } from 'react';

// material
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
} from '@mui/material';

import { dateFormat } from '@utils/customText';

import { addDays } from 'date-fns';

// js
import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// action
import { fetchingUpdatedPricing } from '@actions/propertiesAction';

// component
import useBookingDetails from '@customhooks/useBookingDetails';
import DateRangePicker from './DateRange';

// scss

import { updateTheCalender } from '@reducers/propertyReducer';
import { calculateMinimumStay, updateCalenderList } from '@utils/common';
import { DATE_PICKER_HEADERS } from '@utils/constants';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './dateRangeOptions.scss';

// component start
const DatePickerModal = ({
  open,
  onClose,
  setCheckInDate,
  setCheckOutDate,
  setIsClearDatesModal,
  checkOutDate,
  checkInDate,
  availabilityCalenderList,
  calenderModalError,
  setCalenderModalError,
  handleDatePickerModal,
  isModifyClicked,
  clearDatesModal,
}) => {
  const { totalNumberOfNights, datePickerHeader, setDatePickerHeader } =
    useBookingDetails();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [rerenderKey, setRerenderKey] = useState(0); // Introduce a state for the key
  const [minimumDays, setMinimumDays] = useState(0);

  const location = useLocation();
  const dispatch = useDispatch();
  const parts = location.pathname.split('/');
  const slug = parts[2];

  const searchParams = new URLSearchParams(location?.search);
  const bookingId = searchParams.get('bookingId');
  const propertyId = searchParams.get('propertyId');

  const isDetailPage =
    (location.pathname.includes('villas-in') && parts.length == 3) ||
    (searchParams.has('propertyId') && location.pathname.includes('villas-in'));
  // const copyAvailabilityCalenderList = useSelector(
  //   (state) => state?.property?.copyOfavailabilityDates
  // );
  const { minimumStay } = useSelector((state) => state.property);

  const copyAvailabilityCalenderList = JSON.parse(
    sessionStorage.getItem('copyData')
  );

  const [selectDate, setSelectDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (
      location.pathname === '/booking' &&
      checkInDate &&
      checkOutDate &&
      !open
    ) {
      const startDate = dayjs(checkInDate);
      const endDate = dayjs(checkOutDate);

      if (startDate.isValid() && endDate.isValid()) {
        const startDateFormatted = startDate.format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );
        const endDateFormatted = endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        setSelectDate([
          {
            startDate: new Date(startDateFormatted),
            endDate: addDays(new Date(startDateFormatted), totalNumberOfNights),
            key: 'selection',
          },
        ]);
      } else {
        console.error('Invalid date values');
      }
    }
  }, [location, checkInDate, checkOutDate]);

  const startDate = dayjs(checkInDate, dateFormat.ddmmyy);
  const endDate = dayjs(checkOutDate, dateFormat.ddmmyy);
  const millisecondsDiff = endDate?.valueOf() - startDate?.valueOf();
  const numberOfNights = Math.ceil(millisecondsDiff / (24 * 60 * 60 * 1000));

  const handleClearDates = () => {
    setSelectedStartDate(null);
    setSelectDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    setCheckInDate('');
    setCheckOutDate('');
    setIsClearDatesModal(true);
    dispatch(fetchingUpdatedPricing(false));
    dispatch(updateTheCalender(copyAvailabilityCalenderList));
    setRerenderKey((prevKey) => prevKey + 1);
  };

  const handleSnackbarClose = () => {
    setCalenderModalError(false);
  };

  useEffect(() => {
    const startDate = checkInDate;
    const endDate = checkOutDate;

    if (dayjs(startDate).isSame(endDate, 'day')) {
      // If start date and end date are the same, set check-out date 1 day after check-in date
      const nextDay = new Date(startDate);
      nextDay.setDate(nextDay.getDate() + 2); // Change this to 1 for a 1-day gap
      const endDateFormatted = dayjs(nextDay).format(dateFormat.ddmmyy);
      setCheckOutDate(endDateFormatted);
      setSelectDate([
        {
          startDate: new Date(startDate),
          endDate: new Date(endDateFormatted),
          key: 'selection',
        },
      ]);
    }
  }, [!open]);

  useEffect(() => {
    if (dayjs(checkOutDate).isSame(dayjs(checkInDate).add(0, 'day'), 'day')) {
      setSelectDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
      setCheckOutDate('');
      setCheckInDate('');
      dispatch(fetchingUpdatedPricing(false));
    }
  }, [!open]);

  useEffect(() => {
    if (!checkInDate) {
      setDatePickerHeader(DATE_PICKER_HEADERS.checkInDateHeader);
    } else if (checkInDate === checkOutDate) {
      setDatePickerHeader(DATE_PICKER_HEADERS.checkOutDateHeader);
    } else if (checkInDate && checkOutDate && checkInDate !== checkOutDate) {
      setDatePickerHeader(
        `${numberOfNights ? numberOfNights : 0} nights Selected`
      );
    }

    if (bookingId && location.pathname.startsWith('/villa-detail')) {
      const startDate = dayjs(checkInDate);
      const endDate = dayjs(checkOutDate);
      if (startDate.isValid() && endDate.isValid()) {
        const startDateFormatted = startDate.format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );
        const endDateFormatted = endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        setSelectDate([
          {
            startDate: new Date(startDateFormatted),
            endDate: addDays(new Date(endDateFormatted), totalNumberOfNights),
            key: 'selection',
          },
        ]);
      }
    }

    if (
      (location.pathname.startsWith('/villas') ||
        location.pathname === '/' ||
        isDetailPage ||
        location.pathname.startsWith('/booking')) &&
      checkInDate &&
      checkOutDate
    ) {
      setSelectDate([
        {
          startDate: new Date(checkInDate),
          endDate: new Date(checkOutDate),
          key: 'selection',
        },
      ]);
    }
  }, [checkInDate, checkOutDate, open]);

  const isCallbackAnchor = document.getElementById(
    'detailCheckinCheckoutCallback'
  );

  useEffect(() => {
    const currentDate = new Date();
    if (
      selectDate[0].startDate.toDateString() !== currentDate.toDateString() &&
      selectDate[0].endDate.toDateString() !== currentDate.toDateString()
    ) {
      const startDateFormatted = dayjs(selectDate[0].startDate).format(
        dateFormat.ddmmyy
      );
      const endDateFormatted = dayjs(selectDate[0].startDate).format(
        dateFormat.ddmmyy
      );

      if (propertyId || slug) {
        dispatch(updateTheCalender(copyAvailabilityCalenderList));
      }
      updateCalenderList(
        startDateFormatted,
        endDateFormatted,
        availabilityCalenderList,
        copyAvailabilityCalenderList
      );
    }
  }, [selectDate, propertyId, slug]);

  useEffect(() => {
    const minStays = calculateMinimumStay(
      checkInDate,
      checkOutDate,
      minimumStay
    );
    setMinimumDays(minStays);
  }, [checkInDate, checkOutDate]);

  return (
    <Menu
      className='el-dateRangeModal'
      anchorEl={
        isCallbackAnchor
          ? document.getElementById('detailCheckinCheckoutCallback')
          : document.getElementById('detailCheckinCheckout')
      }
      open={open}
      onClose={onClose}
      // getContentAnchorEl={null}
      anchorReference='anchorEl'
      anchorPosition={{ top: 0, left: 0 }}
      // anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      // disableBackdropClick
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
          //   mb: 1.5,
          mt: 1.5,
        },
      }}
    >
      <div className='EL-date-picker-modal'>
        <DialogTitle id='alert-dialog-title'>
          <span>{datePickerHeader}</span>
          <Button
            variant='text'
            color='primary'
            onClick={handleClearDates}
            disabled={!checkInDate && !checkOutDate}
          >
            Clear Dates
          </Button>
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            setCheckInDate={setCheckInDate}
            setCheckOutDate={setCheckOutDate}
            months={2}
            direction='horizontal'
            availabilityCalenderList={availabilityCalenderList}
            setSelectDate={setSelectDate}
            selectDate={selectDate}
            setIsClearDatesModal={setIsClearDatesModal}
            copyAvailabilityCalenderList={copyAvailabilityCalenderList}
            clearDatesModal={clearDatesModal}
            key={rerenderKey}
          />
        </DialogContent>

        <DialogActions className='EL-calenderAction'>
          <div className='flex sp-bw full-width ai-center EL-selectedNights'>
            <div className='flex ai-center sp-bw'>
              {/* <Typography variant='body1' component={'span'} gutterBottom>
                <b>{numberOfNights ? numberOfNights : 0} - Nights</b> Selected
              </Typography> */}

              {isNaN(numberOfNights) ||
                (numberOfNights < minimumDays &&
                  checkOutDate !== checkInDate && (
                    <Alert variant='standard' severity='error' elevation={0}>
                      Please select at least {minimumDays} nights
                    </Alert>
                  ))}
            </div>

            <div className='flex ai-center EL-calenderButton'>
              <Button
                color='secondary'
                disableElevation
                variant='contained'
                size='large'
                className=''
                disabled={isNaN(numberOfNights) || numberOfNights < minimumDays}
                onClick={handleDatePickerModal}
              >
                Select Guest(s)
              </Button>
            </div>
          </div>
        </DialogActions>
      </div>
    </Menu>
  );
};

export default DatePickerModal;
