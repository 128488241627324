import React, { useState } from 'react';

import { format } from 'date-fns';

import { dateFormat } from '@utils/customText';

// js
import dayjs from 'dayjs';

import { DateRange } from 'react-date-range';

// scss
import { updateTheCalender } from '@reducers/propertyReducer';
import { getDisabledDates, updateCalenderList } from '@utils/common';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch } from 'react-redux';

// component start
const DateRangePicker = ({
  setCheckInDate,
  setCheckOutDate,
  months,
  direction,
  availabilityCalenderList,
  setSelectDate,
  selectDate,
  setIsClearDatesModal,
  copyAvailabilityCalenderList,
  clearDatesModal,
}) => {
  const dispatch = useDispatch();
  const [selectedRange, setSelectedRange] = useState(null);

  // Check if availabilityCalenderList is valid
  const isAvailabilityListValid =
    availabilityCalenderList && typeof availabilityCalenderList === 'object';

  // If availabilityCalenderList is not valid, set an empty array
  const availabilityList = isAvailabilityListValid
    ? Object.entries(availabilityCalenderList).map(([date, availability]) => ({
        date: new Date(date),
        availability,
      }))
    : [];

  const handleRangeChange = (newDateRange) => {
    setSelectedRange(newDateRange);

    setSelectDate([newDateRange.selection]);

    setIsClearDatesModal(false);
    const startDateFormatted = dayjs(newDateRange.selection.startDate).format(
      dateFormat.ddmmyy
    );
    const endDateFormatted = dayjs(newDateRange.selection.endDate).format(
      dateFormat.ddmmyy
    );

    if (
      dayjs(startDateFormatted).format('YYYY-MM-DD') ===
      dayjs(endDateFormatted).format('YYYY-MM-DD')
    ) {
      dispatch(updateTheCalender(copyAvailabilityCalenderList));
    }
    updateCalenderList(
      startDateFormatted,
      endDateFormatted,
      availabilityCalenderList,
      copyAvailabilityCalenderList
    );

    setCheckInDate(startDateFormatted);
    setCheckOutDate(endDateFormatted);
  };

  const customDayContent = (day) => {
    let extraDot = null;
    const availability = availabilityList.find((item) =>
      dayjs(item.date).isSame(day, 'day')
    );

    if (availability && availability.availability === 0) {
      extraDot = <div className='strikethrough' />;
    }

    return (
      <div>
        {extraDot}
        <span>{format(day, 'd')}</span>
      </div>
    );
  };

  const formattedDates = availabilityList?.map((item) => {
    const formattedDate = dayjs(item.date).format('YYYY-MM-DD');
    return {
      ...item,
      formattedDate,
    };
  });

  const startDate =
    formattedDates.length > 0
      ? new Date(formattedDates[0].formattedDate)
      : new Date(dayjs().format('YYYY-MM-DD'));

  const endDate =
    formattedDates.length > 0
      ? new Date(formattedDates[formattedDates.length - 1].formattedDate)
      : dayjs().add(6, 'months').endOf('month').toDate();

  // Getting Disabled Dates
  const disabledDates = getDisabledDates(
    availabilityList,
    selectDate,
    startDate
  );

  return (
    <DateRange
      minDate={startDate}
      maxDate={endDate}
      onChange={handleRangeChange}
      ranges={selectDate}
      moveRangeOnFirstSelection={false}
      editableDateInputs={false}
      months={months}
      weekdayDisplayFormat='EEEEE'
      dayContentRenderer={customDayContent}
      rangeColors={[]}
      direction={direction}
      preventSnapRefocus={true}
      headerTitle={null}
      disabledDates={disabledDates}
      showPreview={false}
    />
  );
};

export default DateRangePicker;
