import Lottie from 'lottie-react';
import * as React from 'react';

const LottieComponent = ({
  animationData,
  loop = true,
  play = true,
  speed = 1,
  height,
  width,
  className,
}) => {
  return (
    <div className={className}>
      <Lottie
        loop={loop}
        play={play}
        speed={speed}
        animationData={animationData}
        style={{ height, width }}
      />
    </div>
  );
};

export default LottieComponent;
