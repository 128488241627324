import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Alert,
  Button,
  ButtonGroup,
  DialogContent,
  DialogTitle,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const GuestButton = ({
  label,
  count,
  onIncrement,
  onDecrement,
  disabled,
  max_adults,
  max_children,
}) => {
  return (
    <ButtonGroup
      className='El-geistButtonGroup'
      color='secondary'
      aria-label={`Increment and Decrement ${label}`}
    >
      <Button
        size='small'
        variant='outlined'
        color='primary'
        onClick={onDecrement}
        disabled={
          (label === 'Children' && count === 0) ||
          (label === 'Adults' && count === 1)
        }
      >
        <RemoveIcon />
      </Button>
      <Button size='small' variant='outlined' color='primary'>
        {count}
      </Button>
      <Button
        size='small'
        variant='outlined'
        color='primary'
        onClick={onIncrement}
        disabled={
          disabled || // Add this condition to disable the increment button
          (label === 'Children' && count === max_children) ||
          (label === 'Adults' && count === max_adults)
        }
      >
        <AddIcon />
      </Button>
    </ButtonGroup>
  );
};

const generateGuestMenuItem = (
  label,
  count,
  onIncrement,
  onDecrement,
  disabled,
  label1,
  max_adults,
  max_children
) => {
  return (
    <MenuItem key={label} disableRipple>
      <ListItemText>
        {label} <div>{label1} </div>
      </ListItemText>
      <GuestButton
        label={label}
        count={count}
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        disabled={disabled}
        max_adults={max_adults}
        max_children={max_children}
      />
    </MenuItem>
  );
};

const AddGuestList = ({
  numberOfChildren,
  numberOfGuests,
  setNumberOfGuests,
  setNumberOfChildren,
  handleUpdatingPricing,
  max_occupancy,
  standard_guests,
  max_adults,
  max_children,
  staahMapping,
}) => {
  const [exceedsMaxOccupancy, setExceedsMaxOccupancy] = useState(true);
  const [extraBed, setExtraBed] = useState(false);
  const [extraAdult, setExtraAdult] = useState(false);
  const [extraChild, setExtraChild] = useState(false);
  const [extraChildLessAdult, setExtraChildLessAdult] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClear = () => {
    setNumberOfGuests(1);
    setNumberOfChildren(0);
    setExceedsMaxOccupancy(false);
  };

  const handleIncrement = (type) => {
    if (type === 'guests') {
      if (numberOfGuests < max_adults) {
        setNumberOfGuests((prevValue) => prevValue + 1);
      } else if (numberOfChildren < max_children) {
        setNumberOfChildren((prevValue) => prevValue + 1);
      }
    } else if (type === 'children') {
      if (numberOfChildren < max_children) {
        setNumberOfChildren((prevValue) => prevValue + 1);
      }
    }
  };

  const handleDecrement = (type) => {
    if (
      type === 'guests' &&
      (numberOfGuests > 1 || (numberOfGuests === 1 && numberOfChildren > 0))
    ) {
      setNumberOfGuests((prevValue) => prevValue - 1);
    } else if (type === 'children' && numberOfChildren > 0) {
      setNumberOfChildren((prevValue) => prevValue - 1);
    } else if (type === 'children' && numberOfGuests > 1) {
      setNumberOfGuests((prevValue) => prevValue - 1);
    }
  };

  const totalGuest = numberOfGuests + numberOfChildren;

  useEffect(() => {
    setExceedsMaxOccupancy(false);

    // to show max max adult and children massage
    const adultRemainingCapacity =
      staahMapping?.ApplicableAdult - numberOfGuests;

    if (numberOfChildren > staahMapping?.ApplicableChild) {
      setExtraChild(true);
    } else {
      setExtraChild(false);
    }

    if (numberOfGuests > staahMapping?.ApplicableAdult) {
      setExtraAdult(true);
    } else if (
      numberOfGuests > staahMapping?.ApplicableAdult &&
      numberOfChildren > staahMapping?.ApplicableChild
    ) {
      setExtraChild(true);
      setExtraAdult(true);
    } else {
      setExtraChild(false);
      setExtraAdult(false);
    }

    if (
      adultRemainingCapacity == 0 &&
      numberOfChildren > staahMapping?.ApplicableChild
    ) {
      setExtraChild(true);
    } else if (adultRemainingCapacity > 0) {
      if (
        numberOfChildren - adultRemainingCapacity >
        staahMapping?.ApplicableChild
      ) {
        setExtraChild(false);
        setExtraChildLessAdult(true);
      } else {
        setExtraChild(false);
        setExtraChildLessAdult(false);
      }
    } else {
      setExtraChildLessAdult(false);
    }

    // to show max occupancy massage
    if (totalGuest >= max_occupancy) {
      setExceedsMaxOccupancy(true);
      setExtraBed(false);
    } else {
      setExceedsMaxOccupancy(false);
    }
  }, [numberOfGuests, numberOfChildren, max_occupancy, standard_guests]);

  const disableIncrement = totalGuest >= max_occupancy;

  const guestMenuItems = [
    generateGuestMenuItem(
      'Adults',
      numberOfGuests,
      () => handleIncrement('guests'),
      () => handleDecrement('guests'),
      disableIncrement,
      'Age 13 years and above',
      max_adults,
      max_children
    ),
    generateGuestMenuItem(
      'Children',
      numberOfChildren,
      () => handleIncrement('children'),
      () => handleDecrement('children'),
      disableIncrement,
      'Age 3-12 years',
      max_adults,
      max_children
    ),
  ];

  return (
    <div className='El-geuestMail'>
      <DialogTitle id='alert-dialog-title'>
        <Typography variant='h6' gutterBottom>
          Total Guests
        </Typography>
        <Button onClick={handleMenuClear} variant='text' color='primary'>
          Clear
        </Button>
      </DialogTitle>
      <DialogContent>
        {guestMenuItems}
        {exceedsMaxOccupancy && (
          <div className='EL-calenderError El-alert '>
            <Alert variant='outlined' severity='warning'>
              Maximum guest occupancy reached {max_occupancy}
            </Alert>
          </div>
        )}
        {(extraAdult || extraChild || extraChildLessAdult) && (
          <div className='EL-calenderError El-alert El-yalloElert'>
            <Alert variant='outlined' severity='error'>
              {extraAdult && extraChild
                ? `Standard limit (Adult ${staahMapping?.ApplicableAdult}, Children ${staahMapping?.ApplicableChild}) exceeded. Additional charges ${staahMapping?.ExAdRate} per extra adult, ${staahMapping?.ExChildRate} per extra child applies`
                : extraAdult
                ? `Standard Adult limit (${staahMapping?.ApplicableAdult}) exceeded. Charges: ${staahMapping?.ExAdRate}/- per adult applies`
                : `Standard Child limit ${
                    !extraChildLessAdult
                      ? `(${staahMapping?.ApplicableChild})`
                      : ''
                  } exceeded. Charges ${
                    staahMapping?.ExChildRate
                  }/- per child applies`}
            </Alert>
          </div>
        )}
        {!isMobile && (
          <Button
            disableElevation
            className='EL-GuestDone'
            size='large'
            variant='contained'
            color='secondary'
            onClick={handleUpdatingPricing}
          >
            Done
          </Button>
        )}
      </DialogContent>
    </div>
  );
};

export default AddGuestList;
